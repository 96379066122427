import api from '../../../api'
export default {

    getallcostitems(params) {
        return api.get('accounting/getCostItems', { params })
    },
    accountinggetitems(params) {
        return api.get('accounting/getItem', { params })
    },
    getcurrency() {
        return api.get('accounting/getCurrency')
    },
    getexchangerate(params) {
        return api.get('accounting/getExchangeRate', { params })
    },
    acctimportexcel(params) {
        return api.post('accounting/importExcel', params)
    },
    updateitemcosting(params) {
        return api.post('accounting/updateItemCosting', params)
    },
    getcurrencydetails(params) {
        return api.get('accounting/getCurrencyDetails', { params })
    },
    addcurrency(params) {
        return api.post('accounting/addCurrency', params)
    },
    deletecurrency(params) {
        return api.post('accounting/deleteCurrency', params)
    },
    showcurrency() {
        return api.get('accounting/showCurrency')
    },
    getallitemcostingsizes(params){
        return api.get('accounting/itemCostingSize', { params })
    },
    additemcostingsize(params) {
        return api.post(`accounting/itemCostingSize`, params)
    },
    showitemcostingsize(params) {
        return api.get(`accounting/itemCostingSize/${params}`)
    },
    storeitemcostingsize(params) {
        return api.post('accounting/itemCostingSize', params)
    },
    updateitemcostingsize(params) {
        return api.post(`accounting/itemCostingSize/`, params)
    },
    destroyitemcostingsize(params) {
        return api.delete(`accounting/itemCostingSize/${params}`)
    },
    importitemcosting(params) {
        return api.post(`accounting/importItemCosting`, params)
    },
 
}
