import api from '../../../api'
export default {

    updatestockvariancestatus(params){
        return api.post(`inventory/stock/variance/update-status`,params);
    },
    savestockvariance(){
        return api.get(`inventory/stock/variance/save`);
    },
    getallstockvariance(params){
        return api.post(`inventory/stock/variance/get-all`,params);
    },
}
