<template>
    <v-app>
     <v-container bg fill-height grid-list-md text-xs-center>
       <v-layout row wrap align-center>
         <v-flex>
           <v-card
                class="mx-auto"
                max-width="450"
                elevation="20"
                bg
                fill-height
                grid-list-md
                text-xs-center
           >
           <v-toolbar
                elevation="2"
               class="ma-0 pa-0"
               prominent
               src="@/assets/ingcoph_bg/ingcoph_login.jpg"
            >
            </v-toolbar>
            <v-card-title>
                <span>Reset your password</span>
            </v-card-title>
            <v-divider class="m-0"></v-divider>
            <v-card-text>
                <v-row class="pa-2">
                    <v-col cols="9">
                        <span>How do you want to get the code to reset your password?</span>
                        <v-radio-group label="Send code via gmail?" v-model="active">
                            <v-radio
                                :value="1"
                                name="active"
                                class="mt-3 mb-3"
                                :label=" maskEmail(GET_EMAIL_USERNAME.email)"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="3">
                        <v-row>
                            <v-col align="center" class="mt-5">
                                <v-icon style="font-size: 70px;">mdi-account-circle</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col align="center">
                                {{ GET_EMAIL_USERNAME.username }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="ma-0"></v-divider>
            <v-card-actions class="py-10">
                <v-row>
                    <v-spacer />
                    <v-col cols="3">
                        <v-btn
                            @click="notYou()"
                            class="pr-3 pl-3"
                            elevation="2"
                            text
                        >
                            NOT YOU?
                        </v-btn>
                    </v-col>
                    <v-col cols="3">
                        <v-btn 
                            :loading="loading"
                            @click="resetSubmit()"
                            class="pr-2 pl-2"
                            type="submit"
                            elevation="2" 
                        >
                            CONTINUE
                        </v-btn>
                    </v-col>
                </v-row>   
            </v-card-actions>
         </v-card>
         </v-flex>
       </v-layout>
     </v-container>
   </v-app>
 </template>
 
 <script>
 import { mapGetters } from 'vuex';
 import swal from 'sweetalert2';
 export default {
     data(){
         return {
            loading: false,
            valid: true,
            active: 1,
         };
     },
     methods: {
        resetSubmit(){
            let payload = {
                email:this.GET_EMAIL_USERNAME.email,
                username:this.GET_EMAIL_USERNAME.username
            }
            this.loading = true;
            this.$store.dispatch('sendEmail', payload).then((success) => {
                this.$router.push({ path: '/recover/code' }).catch((err) => {
                     this.loading = false;
                });
            }).catch((error) => {
                swal.fire({
                    title: "Error Sending Email!",
                    text: "Please contact system administrator for assistance.",
                    icon: "error",
                    heightAuto: false,
                }).then((success) => {
                    this.loading = false;
                    this.$router.push({ path: '/login' })
                })
            })
        },
         notYou(){
             this.$router.push({ path: '/forgotPassword' }).catch((err) => {
                 this.loading = false;
             });
         },
         maskEmail(email) {
            const parts = email.split('@');
            const username = parts[0];
            const domain = parts[1];
            const maskedUsername = username.substr(0, 2) + '*'.repeat(username.length - 2);
            const maskedEmail = maskedUsername + '@' + domain;
            return maskedEmail;
        },
     },
     mounted() {
     },
     computed: {
        ...mapGetters(["GET_EMAIL_USERNAME"]),
     },
     watch: {
        loading:{
            handler(val){
                console.log(val)
            }
        }
     }
 }
 </script>
 
 <style scoped>
 
 </style>