import repository from "@/api/credentials";

const routes = [{
    path: "/operations/stock-variance/:id",
    name: "warehousestockvariance",
    component: () => import('@/views/main/modules/Operations/StockVariance/StockVariancesComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Variance',
    }
}]


export default routes;
