import repository from "@/api/Main/DropDowns/index.js";
export default {
    state: {
        user_select_list: [],
        salesman_positions_list: [],
        salesman_head_list: [],
        customer_list: [],
        items: {
            selection: [],
        },
        item_type: {
            selection: [],
        },
        item_category: {
            selection: [],
        },
        item_uom: {
            selection: [],
        },
        customers: {
            selection: [],
        },
        salesman: {
            selection: [],
        },
        customer_classifications: {
            selection: [],
        },
        customer_category: [],
        accounting_users: [],
        department_role:[],
        sales_by_region:[],
        credit_memo_classification: [],
        credit_memo_selection:[],
        customer_with_credit_memo_selection:[],
        item_selection_exclude_sp:[],
        packing_list_num_selection:[],
        customer_with_packing_list_selection:[],
        vendors_list:[],
        items_exclude_sp:[],
        status:[],
        employee_data_selection:[],
        marital_status:[],
        citizenship:[],
        religion:[],
        contract_type: [],
        employee_superiors:[],
        job_title:[],
        citizenship:[],
        religion:[],
        uom_selection:[],
        job_level_selection:[],
        leave_type_selection:[],
        application_status_selection: [],
        purpose_visit:[],
        backjob_com:[],
        service_customers_end_user:[],
        service_technicians:[],
        items_exclude_sp:[],
        credit_memo_classification: [],
        credit_memo_selection:[],
        customer_with_credit_memo_selection:[],
        item_selection_exclude_sp:[],
        packing_list_num_selection:[],
        customer_with_packing_list_selection:[],
        employee_data:[],
        company_selection:[],
        employee_salary_type_selection:[],
        exclusivity_type_selection:[],
        check_lists:[],
        template:[],
        check_list_template:[],
        client_list : [],
        manpower_request_reason_type_selection :[],
        education_type_selection : [],
        experience_type_selection : [],
        application_form_job_title_selection : [],
        special_requirements_selection: [],
        special_skills_selection: [],
        store_branches_selection: [],
        back_job_selection: {},
        sample_form_selection:[],
        item_qa_checkeds:[],
        emojis:[],
        sp_replacements: [],
        get_specific_list: [],
        unassigned_spare_parts: [],
        clusters: {
            selection: [],
        },
        sub_clusters: {
            selection: [],
        },
    },
    getters: {
        GET_ALL_USERS_SELECT: (state) => state.user_select_list,
        GET_SERVICE_TECNICIANS: (state) => state.service_technicians,
        GET_ALL_SALESMANPOSITIONS_SELECT: (state) =>
            state.salesman_positions_list,
        GET_ALL_SALESMANHEAD_SELECT: (state) => state.salesman_head_list,
        GET_CUSTOMERS_LIST_SELECTION: (state) => state.customer_list,
        GET_ITEM_SELECTION_DEFAULT: (state) => state.items.selection,
        GET_ITEM_SELECTION: (state) => state.items.selection,
        GET_CUSTOMERS_SELECTION: (state) => state.customers.selection,
        GET_SALESMAN_SELECTION: (state) => state.salesman.selection,
        GET_CLASSIFICATIONS_SELECTION: (state) =>
            state.customer_classifications.selection,
        GET_ACCOUNTING_USERS: (state) => state.accounting_users,
        GET_CUSTOMER_CATEGORY_SELECTION: (state) => state.customer_category,
        GET_DEPARTMENT_ROLE_LOAD: (state) => state.department_role,
        SALES_BY_REGION:(state) => state.sales_by_region,
        GET_ITEMS_EXCLUDE_SP: (state) => state.items_exclude_sp,
        GET_BACKJOB_COM: (state) => state.backjob_com,
        GET_CREDIT_MEMO_CLASSIFICATION: (state) => state.credit_memo_classification,
        GET_CREDIT_MEMO_SELECTION:(state) => state.credit_memo_selection,
        GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state) => state.customer_with_credit_memo_selection,
        GET_ITEM_SELECTION_EXCLUDE_SP:(state) => state.item_selection_exclude_sp,
        GET_PACKING_LIST_NUM_SELECTION:(state) => state.packing_list_num_selection,
        GET_CUSTOMER_WITH_PACKING_LIST_SELECTION:(state) => state.customer_with_packing_list_selection,
        GET_ALL_VENDORS_LIST:(state) => state.vendors_list,
        GET_STATUS_SELECTION:(state)=>state.status,
        GET_EMPLOYEE_DATA_SELECTION:(state)=>state.employee_data_selection,
        GET_CONTRACT_TYPE_SELECTION:(state)=>state.contract_type,
        GET_EMPLOYEE_SUPERIOR_SELECTION:(state)=>state.employee_superiors,
        GET_JOB_TITLE_SELECTION:(state)=>state.job_title,
        GET_CITIZENSHIP_SELECTION:(state)=>state.citizenship,
        GET_RELIGION_SELECTION:(state)=>state.religion,
        GET_MARITAL_STATUS_SELECTION:(state)=>state.marital_status,
        GET_UOM_SELECTION:(state)=>state.uom_selection,
        GET_PRF_SELECTION:(state)=>state.prf_selection,
        GET_JOB_LEVEL_SELECTION:(state)=>state.job_level_selection,
        GET_LEAVE_TYPE_SELECTION:(state)=>state.leave_type_selection,
        GET_APPLICATION_STATUS_SELECTION:(state)=>state.application_status_selection,
        GET_PURPOSE_OF_VISIT:(state)=>state.purpose_visit,
        GET_EMPLOYEE_DATA:(state)=>state.employee_data,
        GET_COMPANY_SELECTION:(state)=>state.company_selection,
        GET_EMPLOYEE_SALARY_TYPE_SELECTION:(state)=>state.employee_salary_type_selection,
        GET_SERVICE_CUSTOMERS_END_USER:(state)=>state.service_customers_end_user,
        GET_ITEMS_EXCLUDE_SP:(state) => state.items_exclude_sp,
        GET_ITEMS_EXCLUDE_SP: (state) => state.items_exclude_sp,
        GET_CREDIT_MEMO_CLASSIFICATION: (state) => state.credit_memo_classification,
        GET_CREDIT_MEMO_SELECTION:(state) => state.credit_memo_selection,
        GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state) => state.customer_with_credit_memo_selection,
        GET_ITEM_SELECTION_EXCLUDE_SP:(state) => state.item_selection_exclude_sp,
        GET_PACKING_LIST_NUM_SELECTION:(state) => state.packing_list_num_selection,
        GET_CUSTOMER_WITH_PACKING_LIST_SELECTION:(state) => state.customer_with_packing_list_selection,
        GET_EXCLUSIVITY_TYPE_SELECTION: (state) => state.exclusivity_type_selection,
        GET_CHECK_LISTS_SELECTION:(state) => state.check_lists,
        GET_ITEM_QA_CHECKED:(state) => state.item_qa_checkeds,
        GET_TEMPLATE_SELECTION:(state) => state.template,
        GET_CHECK_LIST_TEMPLATE_SELECTION:(state) => state.check_list_template,

        GET_CLIENT_LIST_SELECTION:(state)=>state.client_list,
        GET_MANPOWER_REQUEST_REASON_TYPE_SELECTION:(state)=>state.manpower_request_reason_type_selection,
        GET_EDUCATION_TYPE_SELECTION:(state)=>state.education_type_selection,
        GET_EXPERIENCE_TYPE_SELECTION:(state)=>state.experience_type_selection,
        GET_APPLICATION_FORM_JOB_TITLE_SELECTION:(state)=>state.application_form_job_title_selection,
        GET_SPECIAL_REQUIREMENTS_SELECTION:(state)=>state.special_requirements_selection,
        GET_SPECIAL_SKILLS_SELECTION:(state)=>state.special_skills_selection,
        GET_STORE_BRANCHES_SELECTION:(state)=>state.store_branches_selection,
        GET_SAMPLE_FORM_SELECTION: (state) => state.sample_form_selection,
        GET_EMOJIS: (state) => state.emojis,
        GET_BACKJOB_SELECTION: (state) => state.back_job_selection,
        GET_SP_REPLACEMENT_SELECTION: (state) => state.sp_replacements,
        GET_SPECIFIC_CHECK_LISTS_SELECTION:(state) => state.get_specific_list,
        GET_UNASSIGNED_SPARE_PARTS: (state) => state.unassigned_spare_parts,
        GET_CLUSTER_SELECTION: (state) => state.clusters.selection,
        GET_SUB_CLUSTER_SELECTION: (state) => state.sub_clusters.selection,
    },
    actions: {
        getAllSelectUsers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .userselectlist()
                    .then((response) => {
                        commit("USERS_LIST_LOAD", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSelectionDepartmentRole({commit}){

                repository
                    .department_role()
                    .then((response) => {

                        commit("DEPARTMENT_ROLE_LOAD", response.data);

                    })
                    .catch((e) => {

                    });


        },
        getALLSalesmanPosition({ commit }) {
            repository.salesmanpositions().then((response) => {
                commit("SALESMAN_POSITIONS_LOAD", response.data);
            });
        },
        getAllsalesmanSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .salesmanSelection()
                    .then((response) => {
                        commit("SALESMAN_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAllsalesmanSelectionFilter({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .salesmanSelection(payload)
                    .then((response) => {
                        commit("SALESMAN_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAllSalesmanHead({ commit },payload) {
            repository.salesmanhead(payload).then((response) => {
                commit("SALESMAN_HEAD_LOAD", response.data);
            });
        },
        async getAllCustomers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .customersSelection()
                    .then((response) => {
                        commit("CUSTOMERS_LIST_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllitemSelectionDefault({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .itemselectiondefault()
                    .then((response) => {
                        commit("ITEM_SELECTION_DEFAULT", response.data.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        itemselection({ commit },payload){
            return new Promise((resolve, reject) => {
                repository
                    .itemselection(payload)
                    .then((response) => {
                        commit("ITEM_SELECTION", response.data.data);
                        resolve(response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllcustomersSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .customersSelection()
                    .then((response) => {
                        commit("CUSTOMERS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllcustomersSelectionFilter({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .customersSelection(payload)
                    .then((response) => {
                        commit("CUSTOMERS_SELECTION", response.data.data);
                        resolve(response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAllcustomerClassificationSelection({ commit }) {
            repository
                .getallcustomerclassificationselection()
                .then((response) => {
                    commit("CLASSIFICATIONS_SELECTION", response.data.data);
                });
        },
        getallcustomerCategory({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getallcustomerCategory()
                    .then((response) => {
                        commit("CUSTOMER_CATEGORY_SELECTION", response.data.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAccountingUsers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .accountingusers()
                    .then((response) => {
                        commit("ACCOUNTING_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getActualWarehouseCodes({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getactualwarehousecodes()
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getSalesByRegionReports({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getSalesByRegionReports()
                    .then((response) => {
                        commit("SALES_BY_REGION", response.data.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async dropdownGet({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .dropdownGet(payload)
                    .then((response) => {
                        commit('ITEMS_EXCLUDE_SP',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getBackJobDropDown({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getBackJobDropDown(payload)
                    .then((response) => {
                        commit('BACKJOB_COM',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async creditMemoClassificationDropdown({ commit }) {
            return new Promise((resolve, reject) => {
                repository.creditMemoClassification().then(response => {
                    commit('CREDIT_MEMO_CLASSIFICATION', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async getVendorsSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getVendorsSelection()
                    .then((response) => {
                        commit("VENDORS_LIST_SELECTION", response.data);
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCreditMemoSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCreditMemoSelection(payload)
                    .then((response) => {
                        commit('CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithCMSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithCMSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithCMSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithCMSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async itemselectionExcludeSp({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .itemselectionExcludeSp(payload)
                    .then((response) => {
                        commit('ITEM_SELECTION_EXCLUDE_SP',response.data)
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },



        async getVendorsSelection({ commit }) {

            return new Promise((resolve, reject) => {
                repository
                    .getVendorsSelection()
                    .then((response) => {
                        commit("VENDORS_LIST_SELECTION", response.data);
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllPackingListNumSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllPackingListNumSelection(payload)
                    .then((response) => {
                        commit('PACKING_LIST_NUM_SELECTION',response.data)
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithPLSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .getAllCustomerWithPLSelection(payload)
                .then((response) => {
                    commit('CUSTOMER_WITH_PACKING_LIST_SELECTION', response.data)
                    resolve(response)
                })
                .catch((e) => {
                    reject();
                });
            });
        },
        getEmployeeDetails({ commit }){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeDetails()
                    .then((response) => {
                        commit("EMPLOYEE_DATA", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeReligionSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeReligionSelection()
                    .then((response) => {
                        commit("RELIGION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeMaritalStatusSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeMaritalStatusSelection()
                    .then((response) => {
                        commit("MARITAL_STATUS", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeCitizenshipSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeCitizenshipSelection()
                    .then((response) => {
                        commit("CITIZENSHIP", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeJobTitleSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeJobTitleSelection()
                    .then((response) => {
                        commit("JOB_TITLE", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getitemUomSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getitemUomSelection()
                    .then((response) => {
                        commit("UOM_SELECTION", response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeContractTypeSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeContractTypeSelection()
                    .then((response) => {
                        commit("CONTRACT_TYPE", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPRFSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getPRFSelection()
                    .then((response) => {
                        commit("PRF_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeSuperiorSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeSuperiorSelection()
                    .then((response) => {
                        commit("EMPLOYEE_SUPERIOR_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminPoSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminPoSelection(payload)
                    .then((response) => {
                        commit("ADMIN_PO_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getJobLevelSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getJobLevelSelection(payload)
                    .then((response) => {
                        resolve(response.data)
                        commit("JOB_LEVEL_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getLeaveTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getLeaveTypeSelection(payload)
                    .then((response) => {
                        resolve(response.data)
                        commit("LEAVE_TYPE_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getApplicationStatusSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getApplicationStatusSelection(payload)
                    .then((response) => {
                        commit("APPLICATION_STATUS_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getManpowerRequestReasonTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getManpowerRequestReasonTypeSelection(payload)
                    .then((response) => {
                        commit("MANPOWER_REQUEST_REASON_TYPE_SELECTION", response.data);
                        resolve(response.data);
                   })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPurposeOfVisit({ commit }, payload) {

            return new Promise((resolve, reject) => {
                repository
                    .getPurposeOfVisit(payload)
                    .then((response) => {
                        commit("PURPOSE_OF_VISIT", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getExclusivityTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getExclusivityTypeSelection(payload)
                    .then((response) => {
                        commit("EXCLUSIVITY_TYPE_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEducationTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEducationTypeSelection(payload)
                    .then((response) => {
                        commit("EDUCATION_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getExperienceTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getExperienceTypeSelection(payload)
                    .then((response) => {
                        commit("EXPERIENCE_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getApplicationFormJobTitlesSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getApplicationFormJobTitlesSelection(payload)
                    .then((response) => {
                        commit("APPLICATION_FORM_JOB_TITLE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSpecialRequirementsSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSpecialRequirementsSelection(payload)
                    .then((response) => {
                        commit("SPECIAL_REQUIREMENTS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSpecialSkillsSelectionSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSpecialSkillsSelection(payload)
                    .then((response) => {
                        commit("SPECIAL_SKILLS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getStoreBranchesSelectionSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getStoreBranchesSelection(payload)
                    .then((response) => {
                        commit("STORE_BRANCHES_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getLists({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getLists(payload).then((response)=>{
					if(response.status == 200){
                        commit('CHECK_LISTS',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getTemplate({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getTemplate(payload).then((response)=>{
					if(response.status == 200){
                        commit('TEMPLATE',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getCheckListTemplate({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getCheckListTemplate(payload).then((response)=>{
					if(response.status == 200){
                        commit('CHECK_LIST_TEMPLATE',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getEmployeeDataDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeDataDropdown(payload)
                    .then((response) => {
                        commit("EMPLOYEE_DATA_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getCompanySelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getCompanySelection(payload)
                    .then((response) => {
                        commit("COMPANY_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeSalaryTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeSalaryTypeSelection(payload)
                    .then((response) => {
                        commit("EMPLOYEE_SALARY_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getClientListSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getClientListSelection(payload)
                    .then((response) => {
                        commit("CLIENT_LIST_SELECTION", response.data);
                        resolve(response.data);
                  })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getItemChecked({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getItemChecked(payload).then((response)=>{
					if(response.status == 200){
                        commit('ITEM_QA_CHECKED',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getEmoji({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getEmoji(payload).then((response)=>{
                    if(response.status == 200){
                        commit('EMOJIS',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getBackJobSelection({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getBackJobSelection(payload).then((response)=>{
                    if(response.status == 200){
                        commit('BACKJOB_SELECTION',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getSpReplacementsSelection({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getSpReplacementsSelection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SP_REPLACEMENT_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSpecificListSelection({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getSpecificListSelection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SPECIFIC_CHECK_LISTS_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getUnassignedSpareParts({commit}) {
            return new Promise((resolve, reject) => {
                repository.getunassignedspareparts().then( response => {
                    if (response.status == 200) {
                        resolve(response);
                        commit('UNASSIGNED_SPARE_PARTS', response.data);
                    }
                }).catch( error => {
                    reject(error);
                })
            })
        },
        getClusterSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getClusterSelection()
                    .then((response) => {
                        commit("CLUSTER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSubClusterSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getSubClusterSelection(payload)
                    .then((response) => {
                        commit("SUB_CLUSTER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
    },
    mutations: {
        USERS_LIST_LOAD: (state, data) => {
            state.user_select_list = data;
        },
        SALESMAN_POSITIONS_LOAD: (state, data) => {
            state.salesman_positions_list = data;
        },
        SALESMAN_HEAD_LOAD: (state, data) => {
            state.salesman_head_list = data;
        },
        SALESMAN_SELECTION: (state, data) => {
            state.salesman.selection = data;
        },
        CUSTOMERS_LIST_SELECTION: (state, data) => {
            state.customer_list = data;
        },
        CLASSIFICATIONS_SELECTION: (state, data) => {
            state.customer_classifications.selection = data;
        },
        ITEM_SELECTION_DEFAULT: (state, data) => {
            state.items.selection = data;
        },
        ITEM_SELECTION: (state, data) => {
            state.items.selection = data;
        },
        CUSTOMERS_SELECTION: (state, data) => {
            state.customers.selection = data;
        },
        ACCOUNTING_SELECTION: (state, data) => {
            state.accounting_users = data;
        },
        CUSTOMER_CATEGORY_SELECTION: (state, data) => {
            state.customer_category = data;
        },
        DEPARTMENT_ROLE_LOAD: (state,data)=>{
            state.department_role = data;
        },
        SALES_BY_REGION:(state, data)=>{
            state.sales_by_region = data
        },
        VENDORS_LIST_SELECTION:(state, data)=>{
            state.vendors_list = data
        },
        ITEMS_EXCLUDE_SP:(state, data)=>{
            state.items_exclude_sp = data
        },
        BACKJOB_COM:(state, data)=>{
            state.backjob_com = data
        },
        CREDIT_MEMO_CLASSIFICATION: (state, data) => {
            state.credit_memo_classification = data
        },
        CREDIT_MEMO_SELECTION:(state, data)=>{
            state.credit_memo_selection = data
        },
        CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state, data)=>{
            state.customer_with_credit_memo_selection = data
        },
        ITEM_SELECTION_EXCLUDE_SP:(state, data)=>{
            state.item_selection_exclude_sp = data
        },
        PACKING_LIST_NUM_SELECTION:(state, data)=>{
            state.packing_list_num_selection = data
        },
        CUSTOMER_WITH_PACKING_LIST_SELECTION:(state, data)=>{
            state.customer_with_packing_list_selection = data
        },
        STATUS:(state,data)=>{
            state.status = data
        },
        EMPLOYEE_DATA:(state,data)=>{
            state.employee_data_selection = data
            state.employee_data_selection.unshift({'value':0,'text':'COMMON ASSET'})
        },
        MARITAL_STATUS:(state,data)=>{
            state.marital_status = data
        },
        CITIZENSHIP:(state,data)=>{
            state.citizenship = data
        },
        RELIGION:(state,data)=>{
            state.religion = data
        },
        JOB_TITLE:(state,data)=>{
            state.job_title = data
        },
        CONTRACT_TYPE:(state,data)=>{
            state.contract_type = data
        },
        EMPLOYEE_SUPERIOR_SELECTION:(state,data)=>{
            state.employee_superiors = data
        },
        UOM_SELECTION:(state,data)=>{
            state.uom_selection = data
        },
        JOB_LEVEL_SELECTION:(state,data)=>{
            state.job_level_selection = data;
        },
        LEAVE_TYPE_SELECTION:(state,data)=>{
            state.leave_type_selection = data;
        },
        APPLICATION_STATUS_SELECTION:(state,data)=>{
            state.application_status_selection = data;
        },
        PURPOSE_OF_VISIT:(state,data)=>{
            state.purpose_visit = data;
        },
        EMPLOYEE_DATA_SELECTION:(state,data)=>{
            state.employee_data = data;
        },
        COMPANY_SELECTION:(state,data)=>{
            state.company_selection = data;
        },
        EMPLOYEE_SALARY_TYPE_SELECTION:(state,data)=>{
            state.employee_salary_type_selection = data;
        },
        CREDIT_MEMO_CLASSIFICATION: (state, data) => {
            state.credit_memo_classification = data
        },
        SERVICE_CUSTOMERS_END_USER:(state,data)=>{
            state.service_customers_end_user = data;
        },
        SERVICE_CUSTOMERS_TECHNICIANS:(state,data)=>{
            state.service_technicians
        },
        CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state, data)=>{
            state.customer_with_credit_memo_selection = data
        },
        ITEM_SELECTION_EXCLUDE_SP:(state, data)=>{
            state.item_selection_exclude_sp = data
        },
        PACKING_LIST_NUM_SELECTION:(state, data)=>{
            state.packing_list_num_selection = data
        },
        CUSTOMER_WITH_PACKING_LIST_SELECTION:(state, data)=>{
            state.customer_with_packing_list_selection = data
        },
        EXCLUSIVITY_TYPE_SELECTION:(state, data)=>{
            state.exclusivity_type_selection = data
        },
        CHECK_LISTS:(state,data)=>{
            state.check_lists = data
        },
        TEMPLATE:(state,data)=>{
            state.template = data
        },
        CHECK_LIST_TEMPLATE:(state,data)=>{
            state.check_list_template = data
        },
        CLIENT_LIST_SELECTION:(state,data)=>{
            state.client_list = data;
        },
        MANPOWER_REQUEST_REASON_TYPE_SELECTION:(state,data)=>{
            state.manpower_request_reason_type_selection = data;
        },
        EDUCATION_TYPE_SELECTION:(state,data)=>{
            state.education_type_selection = data;
        },
        EXPERIENCE_TYPE_SELECTION:(state,data)=>{
            state.experience_type_selection = data;
        },
        APPLICATION_FORM_JOB_TITLE_SELECTION:(state,data)=>{
            state.application_form_job_title_selection = data;
        },
        SPECIAL_REQUIREMENTS_SELECTION:(state,data)=>{
            state.special_requirements_selection = data;
        },
        SPECIAL_SKILLS_SELECTION:(state,data)=>{
            state.special_skills_selection = data;
        },
        STORE_BRANCHES_SELECTION:(state,data)=>{
            state.store_branches_selection = data;
        },
        SAMPLE_FORM_SELECTION:(state, data)=>{
            state.sample_form_selection = data
        },
        EMOJIS:(state, data)=>{
            state.emojis = data
        },
        ITEM_QA_CHECKED:(state, data)=>{
            state.item_qa_checkeds = data
        },
        BACKJOB_SELECTION:(state, data)=>{
            state.back_job_selection = data
        },
        SP_REPLACEMENT_SELECTION:(state, data)=>{
            state.sp_replacements = data
        },
        SPECIFIC_CHECK_LISTS_SELECTION:(state, data)=>{
            state.get_specific_list = data
        },
        UNASSIGNED_SPARE_PARTS: (state, data) => {
            state.unassigned_spare_parts = data
        },
        CLUSTER_SELECTION: (state, data) => {
            state.clusters.selection = data
        },
        SUB_CLUSTER_SELECTION: (state, data) => {
            state.sub_clusters.selection = data
        },
    },
};
