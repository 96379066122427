import api from '../../../api'
export default {

    getDebitMemoType(params){
        return api.get(`accounting/${params.url}`,{params})
    },
    postDebitMemoType(params){
        return api.post(`accounting/${params.url}`,params)
    },
}
