import repository from "@/api/modules/Operations/StockTransfer";

export default {
    state: {
        all_warehouse_transfer:[],
        show_stock_transfer:[],
        get_srf_numlist:[],
    },
    getters: {
        GET_ALL_WAREHOUSE_TRANSFER: (state) => state.all_warehouse_transfer,
        SHOW_STOCK_TRANSFER: (state) => state.show_stock_transfer,
        GET_SRF_NUMLIST: (state) => state.get_srf_numlist,
    },
    actions: {
        getAllWarehouseTransfer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallwarehousetransfers(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_WAREHOUSE_TRANSFER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showStockTransfer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getstocktransfer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STOCK_TRANSFER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSrfNumlist({commit}) {
            return new Promise((resolve, reject) => {
                repository.getsrfnumlist().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SRF_NUMLIST', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeStockTransfer({commit},payload){
            return new Promise((resolve, reject) => {
                repository.storestocktransfer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyStockTransfer({commit},payload){
            return new Promise((resolve, reject) => {
                repository.destroystocktransfer(payload.id).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateStockTransfer({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatestocktransfer(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approveStockTransfer({commit},payload){
            return new Promise((resolve, reject) => {
                repository.approvestocktransfer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getWarehouseItemsInline({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getwarehouseitemsinline(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        ALL_WAREHOUSE_TRANSFER: (state, data) => { (state.all_warehouse_transfer = data) },
        STOCK_TRANSFER: (state, data) => { (state.show_stock_transfer = data) },
        SRF_NUMLIST: (state, data) => { (state.get_srf_numlist = data) },
    }

}
