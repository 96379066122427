import repository from '@/api/modules/it'


export default {
   state:{
        stock_inquiry_batch: [],
        stock_inquiry_table: [],
        stocks_inquiry_dispatch:{},
		stocks_inquiry_tab_is_active:'',

   },
    getters:{
        GET_STOCK_INQUIRY_BATCH: state => state.stock_inquiry_batch,
        GET_STOCK_INQUIRY_TABLE: state => state.stock_inquiry_table,
        GET_STOCKS_INQUIRY_DISPATCH:(state)=>state.stocks_inquiry_dispatch,
        GET_STOCKS_INQUIRY_ACTIVE_TAB_DISPATCH:(state)=>state.stocks_inquiry_tab_is_active,
    },
    actions:{
        getStocksInquiryTable({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getStocksInquiryTable(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response.data.data)
                    commit('STOCK_INQUIRY_TABLE',response.data.data)
                    }
                }).catch((error)=>{
                  reject(error)
                });
            })
        },
        async getStocksInquiryBatch({commit},payload){
            return await new Promise((resolve,reject)=>{
                repository.getStocksInquiryBatch(payload).then((response)=>{
                    resolve(response)
                    commit('STOCK_INQUIRY_BATCH',response.data)
                }).catch((error)=>{
                  reject(error)
                });
           })
        },
    },
    mutations:{
        STOCK_INQUIRY_BATCH : (state, data) => {
            state.stock_inquiry_batch = data;
        },
        STOCK_INQUIRY_TABLE : (state, data) => {
        state.stock_inquiry_table = data;
        },
        STOCKS_INQUIRY_DISPATCH:(state,data)=>{state.stocks_inquiry_dispatch = data},
		STOCKS_INQUIRY_ACTIVE_TAB_DISPATCH:(state,data) =>{state.stocks_inquiry_tab_is_active =data},

    }

}
