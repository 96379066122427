import repository from "@/api/modules/Sales/SalesOrders/index";

export default {
   state:{
        result:null,
        STATUS:[
            'saved',
            'for sales',
            'for accounting',
            'approved',
            'completed',
            'rejected',
            'canceled',
            'deleted',
            'converted',
            'exec approved',
            'partial',
            'for exec approval',
            'stockcard',
            'for cleanup',
            'for credit limit',
            'for credit terms',
            'for scheduling',
            'waiting for stocks',
            'preparing',
            'prepared',
            'checking',
            'checked',
            'invoiced',
        ],
        REFRESH: false,
        printNoOfLines: 27,
   },
    getters:{
        GET_RESULT:(state)=>state.result,
        SALES_ORDERS: (state) => state,
        PRINT_NO_OF_LINES: (state) => state.printNoOfLines,
    },
    actions: {
        salesOrdersPut({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesOrdersPut(payload).then((response)=>{
                    if(response.status == 200){
                        commit('RESULT')
                        commit('REFRESH', true)
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesOrdersPost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesOrdersPost(payload).then((response)=>{
                    if(response.status == 200){
                        commit('RESULT')
                        commit('REFRESH', true)
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesOrdersGet({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesOrdersGet(payload).then((response)=>{
                    if(response.status == 200){
                        commit('RESULT')
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesOrdersDelete({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesOrdersDelete(payload).then((response)=>{
                    if(response.status == 200){
                        commit('RESULT')
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
    },
    mutations:{
        RESULT: (state,data)  => {(state.result = data)},
        REFRESH: (state, data) => { (state.REFRESH = data) },
        PRINT_NO_OF_LINES_UPDATE: (state, data) => {(state.printNoOfLines = data)},
    }
}
