import repository from "@/api/modules/Accounting/CreditDebitMemo";

export default {
    state: {
        
    },
    getters: {
        
    },
    actions: {
        postCreditMemoHistory({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postCreditMemoHistory(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoHistory({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getCreditMemoHistory(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {

    }

}
