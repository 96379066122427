import api from '../../../api'
export default {

    storesalesquotations(params) {
        return api.post('sales-quotations/salesQuotations', params);
    },
    viewsalesquotation(id) {
        return api.get(`sales-quotations/viewSalesQuotation/${id}`);
    },
    salesquotation(params){
        return api.get('sales-quotations/salesQuotations/'+params)
    },
    salesquotationsgetall(params){
        return api.get('sales-quotations/getAll',{params});
    },
    checkcreditlimit(params){
        return api.get('sales-quotations/checkCreditLimit',params)
    },
    checkcreditterms(params){
        return api.get('sales-quotations/checkCreditTerms',params)
    },
    async convertosalesorder(params){
        return await api.post(`sales-quotations/convertToSalesOrder/${params}`)
    },
    salesquotationsupdate(params){
        return api.put(`sales-quotations/salesQuotations/${params.id}`,params)
    },
    salesquotationsdelete(params){
        return api.delete(`sales-quotations/salesQuotations/${params}`)
    },
    exportsalesquotation(params){
        return api.get(`reports/exportSalesQuotation`,{params})
    },
    salesquotationGet(params){
        return api.get(`sales-quotations/${params.url}`,{params})
    },
    salesquotationPost(params){
        return api.post(`sales-quotations/${params.url}`,params)
    }
}
