import repository from "@/api/modules/Operations/StockTransferNew";

export default {
    state: {
        transfer_warehouse:[],
    },
    getters: {
        GET_TRANSFER_WAREHOUSE: (state) => state.transfer_warehouse,
    },
    actions: {
        getTransferWarehouse({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferwarehouse(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TRANSFER_WAREHOUSE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferCreate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transfercreate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferApprove({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferapprove(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferUpdate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferupdate(payload.id,payload.stockTransfer).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferDestroy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferdestroy(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferShip({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transfership(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferRecall({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferrecall(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferRemoveItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferremoveitem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        TRANSFER_WAREHOUSE: (state, data) => { (state.transfer_warehouse = data) },

    }

}
