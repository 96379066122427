import api from '../../../api'
export default {

    getCreditMemoType(params){
        return api.get(`accounting/${params.url}`,{params})
    },
    postCreditMemoType(params){
        return api.post(`accounting/${params.url}`,params)
    },
}
