import repository from "@/api/credentials";

const routes = [
    {
        path: "sales/sales-orders/:id",
        name: "salesorder",
        component: () => import('@/views/main/modules/Sales/SalesOrdersNew/SalesOrderGroupComponent'),
        beforeEnter: (to, from, next) => {
            repository
                .checkAccessUser(to.params.id)
                .then((response) => {
                    if (response.data == "fail") {
                        next("/no-access");
                    } else {
                        next();
                    }
                })
                .catch(() => {});
        },
        meta: {
            requiresAuth: true,
            title: "Sales Orders",
            service: 0,
        },
    },
];

export default routes;
