import repository from "@/api/credentials";

const routes = [{
    path: 'reports/sales/sales-by-customers/:id',
    name: 'sales-by-customers',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByCustomerComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer Report',
    }
},
{
    path: 'reports/sales/sales-by-customer-detailed/:id',
    name: 'sales-by-customer-detailed',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByCustomerDetailedComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (Detailed) Report',
    }
},
{
    path: 'reports/sales/sales-by-customer-packing-lists/:id',
    name: 'sales-by-customer-packing-lists',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByCustomerPackingListComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (DR) Report',
    }
},
{
    path: 'reports/sales/sales-by-items/:id',
    name: 'sales-by-items',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByItemsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Item Report',
    }
},
{
    path: 'reports/sales/sales-by-classifications/:id',
    name: 'sales-by-classifications',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByClassificationComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Classification Report',
    }
},
{
    path: 'reports/sales/top-performing-concept-store/:id',
    name: 'top-performing-concept-store',
    component: () => import('@/views/main/modules/Reports/SalesReports/TopPerformingConceptStore'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Top Performing Concept Store Report',
    }
},
{
    path: 'reports/sales/top-poor-selling/:id',
    name: 'top-poor-selling',
    component: () => import('@/views/main/modules/Reports/SalesReports/TopPoorSellingComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Top/Poor Selling Report',
    }
},
{
    path: 'reports/sales/pending-stock-Card-by-pL/:id',
    name: 'pending-stock-Card-by-pL',
    component: () => import('@/views/main/modules/Reports/SalesReports/PendingStockCardsByPLComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By DR Report',
    }
},
{
    path: 'reports/sales/customer-pending-orders-by-so/:id',
    name: 'customer-pending-orders-by-so',
    component: () => import('@/views/main/modules/Reports/SalesReports/CustomerPendingOrdersBySoComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Pending Orders By SO Report',
    }
},
{
    path: 'reports/sales/customer-pending-orders-by-items/:id',
    name: 'customer-pending-orders-by-items',
    component: () => import('@/views/main/modules/Reports/SalesReports/CustomerPendingOrdersByItemComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Pending Orders By Item Report',
    }
},
{
    path: 'reports/sales/pending-orders-by-items/:id',
    name: 'pending-orders-by-items',
    component: () => import('@/views/main/modules/Reports/SalesReports/PendingOrdersByItemComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending Orders By Item Report',
    }
},
{
    path: 'reports/sales/customer-sold-unsold/:id',
    name: 'customer-sold-unsold',
    component: () => import('@/views/main/modules/Reports/SalesReports/CustomerSoldUnsolItemsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Sold/Unsold Item Report',
    }
},
{
    path: 'reports/sales/new-item/:id',
    name: 'new-item',
    component: () => import('@/views/main/modules/Reports/SalesReports/NewItemsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'New Item Report',
    }
},
{
    path: 'reports/sales/so-converted-to-wpf/:id',
    name: 'so-converted-to-wpf',
    component: () => import('@/views/main/modules/Reports/SalesReports/SOConvertedToWPFComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SO Converted To WPF Report',
    }
},
{
    path: 'reports/sales/sales-order-per-items/:id',
    name: 'sales-order-per-items',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesOrderPerItemReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Order Per Item Report',
    }
},
{
    path: 'reports/sales/booked-vs-invoiced-items/:id',
    name: 'booked-vs-invoiced-items',
    component: () => import('@/views/main/modules/Reports/SalesReports/BookedVsInvoicedOrdersComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Booked vs Invoiced Orders Report',
    }
},
{
    path: 'reports/sales/item-sales-details/:id',
    name: 'item-sales-details',
    component: () => import('@/views/main/modules/Reports/SalesReports/ItemSalesDetailsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Sales Detail Report',
    }
},
{
    path: 'reports/sales/sales-by-region/:id',
    name: 'sales-by-region',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByRegionComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Region',
    }
},
{
    path: 'reports/sales/sales-by-customer-detailed-comparison/:id',
    name: 'sales-by-customer-detailed-comparison',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByCustomerDetailedComparisonComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (Detailed) Report',
    }
},

{
    path: 'reports/sales/item-sales-details-count/:id',
    name: 'item-sales-details-count',
    component: () => import('@/views/main/modules/Reports/SalesReports/ItemSalesDetailsCountComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Sales Detail Count Report',
    }
},
{
    path: 'reports/sales/sales-by-clusters/:id',
    name: 'sales-by-clusters',
    component: () => import('@/views/main/modules/Reports/SalesReports/SalesByClustersComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Clusters Report',
    }
},

{
    path: 'reports/sales/pending-order-stock-analysis/:id',
    name: 'pending-order-stock-analysis',
    component: () => import('@/views/main/modules/Reports/SalesReports/PendingOrderStockAnalysisComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending Order Stock Analysis Report',
    }
},
]

export default routes;
