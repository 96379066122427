import repository from '@/api/Main/DropDowns/index'
import store from '@/store'

export default {
  state: {
    countries: [],
    regions: [],
    province: [],
    cities: [],
  },
  getters: {
    GET_ALL_COUNTRIES: state => state.countries,
    GET_ALL_REGIONS: state => state.regions,
    GET_ALL_PROVINCE: state => state.province,
    GET_ALL_CITIES: state => state.cities,
  },
  actions: {
    getCountries({ commit }) {
      repository.countries().then(response => {
        commit('ALL_COUNTRIES_LOAD', response.data);
      })
    },
    getRegions({ commit }) {
      return new Promise((resolve, reject) => {
        repository.regions().then(response => {
          resolve(response.data);
          commit('ALL_REGIONS_LOAD', response.data)
        });
      })
    },
    getProvince({ commit }) {
      return new Promise((resolve, reject) => {
        repository.province().then(response => {
          resolve(response.data);
          commit('ALL_PROVINCE_LOAD', response.data);
        })
      });
    },
    getCities({ commit }) {
      return new Promise((resolve, reject) => {
        repository.cities().then(response => {
          resolve(response.data);
          commit('ALL_CITIES_LOAD', response.data);
        })
      });
    },
  },
  mutations: {
    ALL_COUNTRIES_LOAD: (state, data) => {
      state.countries = data;
    },
    ALL_REGIONS_LOAD: (state, data) => {
      state.regions = data;
    },
    ALL_PROVINCE_LOAD: (state, data) => {
      state.province = data;
    },
    ALL_CITIES_LOAD: (state, data) => {
      state.cities = data;
    }
  },
};
