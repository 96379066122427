import repository from '@/api/modules/Accounting/AccountsPayable'
import store from '@/store';
export default {
	state: {
		voucher_types:[],
	},
	getters: {
		GET_ALL_PAYMENT_VOUCHER_TYPES: (state) => state.voucher_types
	},
	actions:{
		getAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayableGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		postAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayablePost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		putAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayablePut(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayableDelete(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		createVendorPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.createvendorpaymentdetail(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		getVendorPaymentList({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getVendorPaymentList(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		updatePaymentVoucher({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatePaymentVoucher(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
	},
	mutations:{
		VOUCHER_TYPES: (state, data) => { (state.voucher_types = data) },
	},
}
