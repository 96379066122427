import repository from '@/api/modules/MasterData'

export default {
  state: {
    salesman: [],
    salesmancreatesuccess: false,
    selected_saleman_head: [],
    salesman_dialog: false,
    salesman_loaded: false,
    get_salesman_lists: [],
    current_salesman: [],
  },
  getters: {
    GET_SALESMAN_LIST: state => state.salesman,
    NEW_SALESMAN: state => state.salesmancreatesuccess,
    GET_SELECTED_SALESMAN_HEAD: state => state.selected_saleman_head,
    SALESMAN_DIALOG: state => state.salesman_dialog,
    GET_SALESMAN_LOADED: state => state.salesman_loaded,
    GET_SALESMAN_LISTS: (state) => state.get_salesman_lists,
    GET_CURRENT_SALESMAN: (state) => state.current_salesman,
  },
  actions: {
    getSalesman({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .salesman()
          .then(({ data }) => {
            resolve(data);
            commit('SALESMAN_LIST', data);
            commit('SALESMAN_LOADED', true);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    addsalesman({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addsalesman(payload)
          .then(({ data }) => {
            resolve(data);
            commit('NEW_SALESMAN', true);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    editsalesman({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        user: payload.payload.user,
        salesman_position: payload.payload.salesman_position,
        salesman_head: payload.payload.salesman_head,
        location: payload.payload.location,
        sales_coordinator: payload.payload.sales_coordinator,
        region: payload.payload.region,
        monthly_target: payload.payload.monthly_target,
        salesman_profile: payload.payload.salesman_profile,
        clusters: payload.payload.clusters,
        sub_clusters: payload.payload.sub_clusters,
      };

      repository.updatesalesman(params).then(() => {
        commit('NEW_SALESMAN', true);
      })
    },
    deleteSalesman({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deletesalesman(payload)
          .then(({ data }) => {
            resolve(data);
            commit('REMOVE_SALESMAN', payload);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getSalesmanList({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .getsalesmanlist()
          .then(({ data }) => {
            resolve(data);
            commit("SALESMAN_LISTS", data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    getCurrentSalesman({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .getcurrentsalesman()
          .then(({ data }) => {
            resolve(data);
            commit("CURRENT_SALESMAN", data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
  },
  mutations: {
    SALESMAN_LIST: (state, data) => {
      state.salesman = data;
    },
    SELECTED_SALESMAN_HEAD: (state, data) => {
      state.selected_saleman_head = data;
    },
    REMOVE_SALESMAN: (state, id) => (state.salesman = state.salesman.filter(salesman => salesman.id !== id)),
    NEW_SALESMAN: (state, data) => {
      state.salesmancreatesuccess = data;
    },
    SALESMAN_DIALOG: (state, data) => {
      state.salesman_dialog = data;
    },
    SALESMAN_LISTS: (state, data) => {
      state.get_salesman_lists = data;
    },
    CURRENT_SALESMAN: (state, data) => {
      state.current_salesman = data;
    },
    SALESMAN_LOADED: (state, data) => {
      state.salesman_loaded = data
    },
  },
};
