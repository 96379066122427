import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import exceljs from 'exceljs';
import fs from 'file-saver';
import VueCurrencyFilter from 'vue-currency-filter';
window.$ = window.jQuery = require('jquery');
import { VueMaskDirective } from 'v-mask';
import jsZIP from 'jszip'

Vue.use(VueCurrencyFilter, {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;
window.Moment = moment;
Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery'); // you'll have this.$jQuery anywhere in your vue project
      }
  });

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm A')
  }
});

Vue.filter('formatDateTime24hours', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY HH:mm')
  }
});

Vue.filter('formatMonth', function (value) {
  if (value) {
    return moment(String(value)).format('MM-YYYY')
  }
});

Vue.filter('formatMonthSoa', function (value) {
  if (value) {
    return moment(String(value)).format('MMM YYYY')
  }
});

Vue.filter('thousandSeprator', function (value) {
  if (value !== '' || value !== undefined || value !== 0 || value !== '0' || value !== null) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
      return value;
  }
});

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter('uppercase', function (value) {
  if (!value) return '';
  return value.toUpperCase()
})

Vue.filter('percentTwoDecimals', function (value, decimal_places = 2) {
    if (!value) {
        value = 0
    }

    value = value * 100
    value = Math.round(value * Math.pow(10, decimal_places)) / Math.pow(10, decimal_places)
    value = value + '%'
    return value
})

Vue.filter('formatDateWithDay', function (value) {
  if (value) {
    return moment(String(value)).format('dddd (MMMM D, YYYY)');
  }
});

Vue.filter('formatLongDate', function (value) {
  if (value) {
  }
});

import _ from 'lodash'
window._ = _

Object.defineProperty(Vue.prototype, 'ENUMS', { value: window.ENUMS });
Object.defineProperty(Vue.prototype, 'Moment', { value: window.Moment });
Object.defineProperty(Vue.prototype, 'Lodash', { value: window._ });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  moment,
  jsPDF,
  exceljs,
  fs,
  VueCurrencyFilter,
  jQuery,
  VueMaskDirective,
  jsZIP,
}).$mount('#app');
