export default {
  state: {
    dialog: false,
    sub_dialog: false,
    action: '',
    load:false,
    celebrant_dialog:false,
  },
  getters: {
    DIALOGS: state => state.dialog,
    CELEBRANTS_DIALOGS: state => state.celebrant_dialog,
    SUB_DIALOG: state => state.sub_dialog,
    ACTION: state => state.action,
    LOAD:state => state.load
  },
  mutations: {
    DIALOG: (state, data) => {
      state.dialog = data;
    },
    ADD_DIALOG: (state, data) => {
      state.dialog = data;
    },
    ACTION: (state, data) => {
      state.action = data;
    },
    SUB_DIALOG: (state, data) => {
      state.sub_dialog = data;
    },
    LOAD:(state,data) =>{
        state.action = data
    },
    CELEBRANTS_DIALOG_MUTATE: (state, data) => {
      state.celebrant_dialog = data;
    },
  },
  actions: {},
};
