import repository from "@/api/modules/Operations/StockCards/index";

export default {
    state: {

    },
    getters: {

    },
    actions: {
        StockCardsGet({commit},payload){
            return new Promise((resolve, reject) => {
                repository.stockcardsGET(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        StockCardsDelete({commit},payload){
            return new Promise((resolve, reject) => {
                repository.stockcardsDelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        StockCardsPut({commit},payload){
            return new Promise((resolve, reject) => {
                repository.stockcardsPut(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        StockCardsPost({commit},payload){
            return new Promise((resolve, reject) => {
                repository.stockcardsPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }

    },
    mutations: {

    }

}
