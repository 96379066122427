import repository from "@/api/modules/Operations/Inventory";

export default {
    state: {
        export_pending_so_list_report: []
    },
    getters: {
        GET_PENDING_SO_LIST_REPORT: (state) => state.export_pending_so_list_report
    },
    actions: {
        exportPendingSoListReport({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.exportpendingsolistreport(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PENDING_SO_LIST_REPORT', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        PENDING_SO_LIST_REPORT: (state, data) => { (state.export_pending_so_list_report = data) }
    }

}
