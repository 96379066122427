import repository from "@/api/credentials";

export default {
    state: {
        sidenav: [],
        reports: [],
        active_link: "",
    },
    getters: {
        SIDENAV_LOAD: (state) => state.sidenav,
        SIDENAV_REPORTS: (state) => state.reports,
        LINK_ACTIVE: (state) => state.active_link,
    },
    mutations: {
        SIDENAV_LOAD: (state, data) => {
            state.sidenav = data;
        },
        SIDENAV_REPORTS: (state, data) => {
            state.reports = data;
        },
        LINK_ID: (state, data) => {
            state.link_id = data;
        },
        LINK_ACTIVE: (state, data) => {
            state.active_link = data;
        },
    },
    actions: {
        renderSideNav({ commit }) {
            return new Promise(async (resolve, reject) => {
                await repository
                    .sidenav()
                    .then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem(
                                "side_menu_bar",
                                JSON.stringify(response.data)
                            );
                            resolve(response);
                            commit("SIDENAV_LOAD", response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
