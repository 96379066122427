import api from '../../../api'
export default {

    transferwarehouse(){
        return api.get(`inventory/stock/transfer/warehouse`);
    },
    transfercreate(params){
        return api.post(`inventory/stock/transfer/create`,params);
    },
    transferapprove(id){
        return api.post(`inventory/stock/transfer/approve/${id}`);
    },
    transferupdate(id,params){
        return api.post(`inventory/stock/transfer/update/${id}`,params);
    },
    transferdestroy(id){
        return api.post(`inventory/stock/transfer/destroy/${id}`);
    },
    transfership(id,params){
        return api.post(`inventory/stock/transfer/ship/${id}`,params);
    },
    transferrecall(id){
        return api.post(`inventory/stock/transfer/recall/${id}`,);
    },
    transferremoveitem(id){
        return api.post(`inventory/stock/transfer/remove-item/${id}`,);
    },

}
