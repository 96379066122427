import api from '../../../api'

export default{
    addwarehousepersonnel(params) {
        return api.post("warehouse-personnel/add-warehouse-personnel", params);
    },
    async getallwarehousepersonnel(params) {
       return await api.get("warehouse-personnel/get-all-personnel", {params});
    },
    updatewarehousepersonnel(params) {
        return api.put("warehouse-personnel/update", {params});
    },
    deletewarehousepersonnel(params) {
        return api.put("warehouse-personnel/delete", {params});
    },
    getallwarehousepersonnelpreparer() {
        return api.get("warehouse-personnel/warehouse_preparer");
    },
    getallwarehousepersonnelchecker() {
        return api.get("warehouse-personnel/warehouse_checker");
    },
    
}
