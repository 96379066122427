<template>

        <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
                <v-flex>
                    <v-card
                        class="mx-auto"
                        max-width="450"
                        elevation="20"
                        bg
                        fill-height
                        grid-list-md
                        text-xs-center
                    >
                    <v-toolbar
                        elevation="2"
                        class="ma-0 pa-0"
                        prominent
                        src="@/assets/ingcoph_bg/ingcoph_login.jpg"
                        >
                        </v-toolbar>
                        <v-card-title>
                            <span>Reset your password</span>
                        </v-card-title>
                        <v-divider class="m-0"></v-divider>
                        <v-card-text>
                            <v-row class="pa-2">
                                <v-col cols="10" >
                                    <span>Enter a new password below to change your password</span>
                                    <v-text-field 
                                        :append-icon="password.isShowNew ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="password.isShowNew ? 'text' : 'password'"
                                        @click:append="password.isShowNew = !password.isShowNew"
                                        class="mt-4"
                                        outlined
                                        dense
                                        id="new_password" 
                                        label="New Password:" 
                                        v-model="new_password"
                                        counter
                                    />
                                    <span v-for="errors in errors.new_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                                    <v-col cols="10">
                                        <v-text-field 
                                            :append-icon="password.isShowConfirmed ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="password.isShowConfirmed ? 'text' : 'password'"
                                            @click:append="password.isShowConfirmed = !password.isShowConfirmed"
                                            class="mb-2"
                                            outlined
                                            dense
                                            id="new_password_confirmation" 
                                            label="Confirm New Password" 
                                            v-model="new_password_confirmation" 
                                            counter
                                        />
                                        <span v-for="errors in errors.new_password_confirmation" class="text-danger" v-bind:key="errors">{{errors}}</span>
                                    </v-col>
                                </v-row>
                        </v-card-text>
                        <v-divider class="ma-0"></v-divider>
                        <v-card-actions class="py-10">
                            <v-row>
                                <v-spacer />
                                <v-col cols="3">
                                    <v-btn
                                        @click="cancel()"
                                        class="pr-4 pl-4"
                                        elevation="2"
                                        text
                                    >
                                        CANCEL
                                    </v-btn>
                                </v-col>
                                <v-col cols="3">
                                    <v-btn 
                                        :loading="loading"
                                        @click="submitPassword()"
                                        class="pr-2 pl-2"
                                        type="submit"
                                        elevation="2" 
                                    >
                                        CONFIRM
                                    </v-btn>
                                </v-col>
                            </v-row>   
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            errors: [],
            loading: false,
            new_password: '',
            new_password_confirmation: '',
            password:{
                isShowCurrent: false,
                isShowNew: false,
                isShowConfirmed: false
            }
        }
    },
    methods: {
        submitPassword(){
            swal.fire({
            title: "",
            text: "Are you sure you want to Change Password?",
            icon: "warning",
            heightAuto: false,
            showConfirmButton:true,
            showCancelButton  :  true,
            reverseButtons:true,
            allowOutsideClick :  false,
            confirmButtonColor: '#397373',
            cancelButtonColor : 'grey',
            confirmButtonText:'Confirm',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.loading = true
                    this.errors = []
                    let payload = {
                        username:                  this.GET_EMAIL_USERNAME.username,
                        new_password:              this.new_password,
                        new_password_confirmation: this.new_password_confirmation,
                    }
                    this.$store.dispatch('resetPassword',payload).then(response => {
                        swal.fire({
                            title: "Password Reset Successful!",
                            icon: "success",
                            heightAuto: false,
                        }).then((success) => {
                            this.loading = false;
                            this.resetFields();
                            this.$router.push({ path: '/' })
                        })
                    }).catch(error => {
                        if(error.response.status == 422){
                            this.errors = error.response.data.errors
                            this.loading = false
                            this.resetFields();
                        }
                    });
                }
            });
        },
        resetFields() {
            this.new_password               = ''
            this.new_password_confirmation  = ''
        },
        cancel() {
            this.$router.push({ path: '/' }).catch((err) => {
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapGetters(["GET_EMAIL_USERNAME"]),
    },
     mounted() {
     }
}
</script>

<style scoped>

</style>