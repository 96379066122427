import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/banks/:id",
    name: "acctbanks",
    component: () => import('@/views/main/modules/Accounting/Views/BanksView.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Banks',
    }
}]

export default routes;
