import repository from "@/api/modules/Operations/WarehouseReturn";

export default {
    state: {
        view_dialog:false,
        status:0,
        selected:{
            warehouse:'',
            series:'',
            remarks:'',
            craeted_by:'',
            created_at:''
        }
    },
    getters: {
        WIR_VIEW_DIALOG: state => state.view_dialog,
        WIR_STATUS:state=>state.status,
        WIR_SELECTED:state=>state.selected
    },
    actions: {
        async warehouseReturnPost({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.warehouseReturnPost(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async warehouseReturnGet({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.warehouseReturnGet(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations:{
        WIR_VIEW_DIALOG: (state, data) => {
            state.view_dialog = data;
        },
        WIR_STATUS: (state, data) => {
            state.status = data;
        },
        WIR_SELECTED: (state, data) => {
            state.selected = data;
        },
    }
}
