import repository from "@/api/modules/Accounting/ItemCosting";

export default {
    state: {
        all_cost_item: [],
        accounting_get_item:[],
        currency:[],
        exchange_rate:[],
        currency_details:[],
        show_currency:[],
        all_item_costing_size: [],
        show_item_costing_size: [],
    },
    getters: {
        GET_ALL_COST_ITEM: (state) => state.all_cost_item,
        GET_ACCOUNTING_ITEM: (state) => state.accounting_get_item,
        GET_CURRENCY: (state) => state.currency,
        GET_EXCHANGE_RATE: (state) => state.exchange_rate,
        GET_CURRENCY_DETAILS: (state) => state.currency_details,
        GET_SHOW_CURRENCY: (state) => state.show_currency,
        GET_ALL_ITEM_COSTING_SIZE: (state) => state.all_item_costing_size,
        GET_SHOW_ITEM_COSTING_SIZE: (state) => state.show_item_costing_size,
    },
    actions: {
        getAllCostItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallcostitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_COST_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        accountingGetItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.accountinggetitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ACCT_GET_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCurrency({commit}) {
            return new Promise((resolve, reject) => {
                repository.getcurrency().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CURRENCY', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getExchangeRate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getexchangerate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EXCHANGE_RATE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        acctImportExcel({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.acctimportexcel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemCosting({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateitemcosting(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCurrencyDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcurrencydetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CURRENCY_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        addCurrency({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.addcurrency(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCurrency({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletecurrency(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showCurrency({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.showcurrency(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_CURRENCY', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemCostingSizes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getallitemcostingsizes(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_COSTING_ITEM', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        showItemCostingSize({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.showitemcostingsize(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_COSTING_SIZE', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeItemCostingSize({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.storeitemcostingsize(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateItemCostingSize({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updateitemcostingsize(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        destroyItemCostingSize({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemcostingsize(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        importItemCosting({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.importitemcosting(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        ALL_COST_ITEM: (state, data) => { (state.all_cost_item = data) },
        ACCT_GET_ITEMS: (state, data) => { (state.accounting_get_item = data) },
        CURRENCY: (state, data) => { (state.currency = data) },
        EXCHANGE_RATE: (state, data) => { (state.exchange_rate = data) },
        CURRENCY_DETAILS: (state, data) => { (state.currency_details = data) },
        SHOW_CURRENCY: (state, data) => { (state.show_currency = data) },
        ALL_ITEM_COSTING_ITEM: (state, data) => { (state.all_item_costing_size = data) },
        SHOW_ITEM_COSTING_SIZE: (state, data) => { (state.show_item_costing_size = data) }
    }

}
