import repository from '@/api/modules/Operations/Outbound'
import it_repository from '@/api/modules/it'

export default {
  state: {
    get_all_outbound: [],
    view_outbound: [],
    add_new_outbound: [],
    edit_outbound: [],
    receive_outbound: [],
    approve_outbound: [],
    reject_outbound: [],
    delete_outbound: [],
    export_outbound: [],
  },
  getters: {
    GET_ALL_OUTBOUND: state => state.get_all_outbound,
    VIEW_OUTBOUND: state => state.view_outbound,
    ADD_NEW_OUTBOUND: state => state.add_new_outbound,
    EDIT_OUTBOUND: state => state.edit_outbound,
    RECEIVE_OUTBOUND: state => state.receive_outbound,
    APPROVE_OUTBOUND: state => state.approve_outbound,
    REJECT_OUTBOUND: state => state.reject_outbound,
    DELETE_OUTBOUND: state => state.delete_outbound,
    EXPORT_OUTBOUND: state => state.export_outbound,
  },
  actions: {
    getAllOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getalloutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    viewOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .viewoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('V_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    addNewOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addnewoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('NEW_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    editOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .editoutbound(payload)
          .then(({ data }) => {
            resolve(data);
            commit('E_OUTBOUND', true)
          })
          .catch(e => {
            reject(e);
          })
      });
    },
    receiveOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .receiveoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REC_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    approveOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .approveoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('APP_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    rejectOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .rejectoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REJ_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    deleteOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deleteoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('DEL_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    exportOutbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .exportoutbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('EXP_OUTBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    }
  },
  mutations: {
    ALL_OUTBOUND: (state, data) => {
      state.get_all_outbound = data;
    },
    V_OUTBOUND: (state, data) => {
      state.view_outbound = data;
    },
    NEW_OUTBOUND: (state, data) => {
      state.add_new_outbound = data;
    },
    E_OUTBOUND: (state, data) => {
      state.edit_outbound = data;
    },
    REC_OUTBOUND: (state, data) => {
      state.receive_outbound = data;
    },
    APP_OUTBOUND: (state, data) => {
      state.approve_outbound = data;
    },
    REJ_OUTBOUND: (state, data) => {
      state.reject_outbound = data;
    },
    DEL_OUTBOUND: (state, data) => {
      state.delete_outbound = data;
    },
    EXP_OUTBOUND: (state, data) => {
      state.export_outbound = data;
    },
  },
}
