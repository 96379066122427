
import api from "../../api";
export default {
    userselectlist() {
        return api.get("dropdown/users-list");
    },
    salesmanpositions() {
        return api.get("dropdown/salesmanpositions-list");
    },
    salesmanhead(params) {
        return api.get("dropdown/salesman-head-list",{params});
    },
    salesman() {
        return api.get("dropdown/salesman");
    },
    salesmancoor() {
        return api.get("dropdown/salesman-coordinator");
    },
    countries() {
        return api.get("dropdown/countries");
    },
    regions() {
        return api.get("dropdown/regions");
    },
    province() {
        return api.get("dropdown/province");
    },
    cities() {
        return api.get("dropdown/cities");
    },
    vendortype() {
        return api.get("dropdown/vendor-type");
    },
    itemselectiondefault() {
        return api.get("/dropdown/items", { params: { types: [1, 2] } });
    },
    affiliatecustomer() {
        return api.get("dropdown/affiliate-customer");
    },
    branddiscount() {
        return api.get("dropdown/brand-discount");
    },
    getallcustomerclassificationselection() {
        return api.get("dropdown/classifications");
    },
    getallcustomerCategory() {
        return api.get("dropdown/customer-category");
    },
    itemselection(params) {
        return api.get("/dropdown/items", {params});
    },
    customersSelection(params) {
        return api.get("/dropdown/customers", {params});
    },
    salesmanSelection(params) {
        return api.get("/dropdown/salesman", {params});
    },
    vehicles() {
        return api.get("/dropdown/vehicles-list");
    },
    promos() {
        return api.get("/dropdown/getPromos");
    },
    accountingusers() {
        return api.get("/dropdown/getAccountingUsers");
    },
    department_role(){
        return api.get('/dropdown/department-list-role');
    },
    getactualwarehousecodes(){
        return api.get('/dropdown/getActualWarehouseCodes');
    },
    getSalesByRegionReports(){
        return api.get('/dropdown/getRegions');
    },
    getVendorsSelection(){
        return api.get('/dropdown/getVendorsSelection');
    },
    dropdownGet(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getBackJobDropDown(params){
        return api.get(`/dropdown/getBackJobDropDown`)
    },
    creditMemoClassification() {
        return api.get(`/dropdown/creditMemoClassificationDropdown`)
    },
    getAllCreditMemoSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllCustomerWithCMSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    itemselectionExcludeSp(){
        return api.get('/dropdown/item-selection-exclude-sp')
    },
    getAllPackingListNumSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllCustomerWithPLSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getEmployeeDetails(){
        return api.get('/dropdown/EmployeeDetails');
    },
    getAdminCategories(){
        return api.get('/dropdown/getAdminCategories');
    },
    getEmployeeReligionSelection(){
        return api.get('/dropdown/getReligion');
    },
    getEmployeeMaritalStatusSelection(){
        return api.get('/dropdown/getMaritalStatus');
    },
    getEmployeeCitizenshipSelection(){
        return api.get('/dropdown/getCitizenship');
    },
    getEmployeeJobTitleSelection(){
        return api.get('/dropdown/getJobTitles');
    },
    getEmployeeContractTypeSelection(){
        return api.get('/dropdown/getContractTypes');
    },
    getEmployeeSuperiorSelection(){
        return api.get('/dropdown/getEmployeeSuperior');
    },
    getitemUomSelection(){
        return api.get('/dropdown/item-uom');
    },
    getPRFSelection(){
        return api.get('/dropdown/getPrfNumber');
    },
    getAdminPoSelection(params){
        return api.get('/dropdown/adminPurchaseOrderSelection',{params});
    },
    getJobLevelSelection(params){
        return api.get('/dropdown/getJobLevel',{params});
    },
    getLeaveTypeSelection(params){
        return api.get('/dropdown/getLeaveType',{params});
    },
    getApplicationStatusSelection(params){
        return api.get('/dropdown/getApplicationStatus',{params});
    },
    creditMemoClassification() {
        return api.get(`/dropdown/creditMemoClassificationDropdown`)
    },
    getAllCreditMemoSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllCustomerWithCMSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getPurposeOfVisit(){
        return api.get('/dropdown/getPurposeOfVisit')
    },
    getEmployeeDataDropdown(params){
        return api.get('/dropdown/getEmployeeDataDropdown',{params});
    },
    getCompanySelection(params){
        return api.get('/dropdown/getCompanySelection',{params});
    },
    getEmployeeSalaryTypeSelection(params){
        return api.get('/dropdown/getEmployeeSalaryTypeSelection',{params});
    },
    getAllCustomerWithPLSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getExclusivityTypeSelection(){
        return api.get('/dropdown/exclusivityTypeDropdown')
    },
    getallcustomercodeselection() {
        return api.get("dropdown/customer_codes");
    },
    getWarehousesRequest() {
        return api.get("dropdown/stock/request/warehouse");
    },
    getLists(params){
        return api.get('dropdown/check-list', {params});
    },
    getTemplate(params){
        return api.get("dropdown/list-template/{id}", {params});
    },
    getCheckListTemplate(params){
        return api.get("dropdown/check-list-template", {params});
    },
    getClientListSelection(params){
        return api.get('/dropdown/getClientList',{params});
    },
    getManpowerRequestReasonTypeSelection(params){
        return api.get('/dropdown/getManpowerRequestReasonType',{params});
    },
    getEducationTypeSelection(params){
        return api.get('/dropdown/getEducationType',{params});
    },
    getExperienceTypeSelection(params){
        return api.get('/dropdown/getExperienceType',{params});
    },
    getApplicationFormJobTitlesSelection(params){
        return api.get('/dropdown/getApplicationFormJobTitles',{params});
    },
    getSpecialRequirementsSelection(params){
        return api.get('/dropdown/getSpecialRequirements',{params});
    },
    getSpecialSkillsSelection(params){
        return api.get('/dropdown/getSpecialSkills',{params});
    },
    getStoreBranchesSelection(params){
        return api.get('/dropdown/getStoreBranches',{params});
    },
    getItemChecked(params){
        return api.get('dropdown/item-checked', {params});
    },
    getEmoji(params){
        return api.get('dropdown/getEmoji',{params});
    },
    getBackJobSelection(params){
        return api.get('dropdown/getBackJobSelection',{params});
    },
    getSpReplacementsSelection(params){
        return api.get('dropdown/getSpReplacementsSelection',{params});
    },
    getSpecificListSelection(params){
        return api.get('dropdown/getSpecificListSelection',{params});
    },
    getunassignedspareparts(params){
        return api.get('dropdown/unassigned-spare-parts', {params});
    },
    getClusterSelection(params) {
        return api.get('dropdown/getClusterSelection', { params })
    },
    getSubClusterSelection(params) {
        return api.get('dropdown/getSubClusterSelection', { params })
    },
};
