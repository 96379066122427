import repository from '@/api/modules/MasterData';
import swal from 'sweetalert2'

export default {
    state: {
        clusters: [],
        selected_cluster: {},
        newcluster: false,
    },
    getters: {
        GET_ALL_CLUSTERS: state => state.clusters,
        GET_EDIT_CLUSTERS: state => state.selected_cluster,
        NEW_CLUSTER: state => state.newcluster,
    },
    mutations: {
        NEW_CLUSTER: (state, data) => {
            state.newcluster = data;
        },
        EDIT_CLUSTERS: (state, data) => {
            state.selected_cluster = data;
        },
        CLUSTER_SELECTIONS: (state, data) => {
        },
    },
    actions: {
        getClusters({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getclusters(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        getSubClusters({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getsubclusters(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        showCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.showcluster(payload).then(response => {
                    if (response.status == 200) {
                        commit('EDIT_CLUSTERS', response.data)
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        addCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        addSubCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addsubcluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        updateCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updatecluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        updateSubCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updatesubcluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        deleteCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.deletecluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
        deleteSubCluster({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.deletesubcluster(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    })
                })
            })
        },
    },
}
