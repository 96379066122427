import api from '../../../api'
export default {
  getallinbound (params) {
    return api.get('inbound/getAll', { params: params });
  },
  viewinbound(params) {
    return api.get(`/inbound/inbound/${params}`,{params});
  },
  addnewinbound(params) {
    return api.post('/inbound/inbound', params);
  },
  editinbound(params) {
    return api.put('/inbound/edit-inbound', params);
  },
  receiveinbound(params) {
    return api.post(`/inbound/receiveInbound/${params}`);
  },
  approveinbound(params) {
    return api.post(`/inbound/approveInbound/${params}`);
  },
  rejectinbound(params) {
    return api.post(`/inbound/rejectInbound/${params}`);
  },
  deleteinbound(params) {
    return api.delete(`/inbound/delete-inbound/${params}`);
  },
  exportinbound(params) {
    return api.post('inbound/exportInbound', params);
  },
  exportlpitoexcel(params) {
    return api.get('inbound/export_all_lpi',params);
  },
  uploadinboundfile(params) {
    return api.post('inbound/upload-inbound-files',params);
  },
  getinboundfiles(id) {
    return api.get(`inbound/get-inbound-files/${id}`);
  },
  deleteinboundfile(params) {
    return api.post('inbound/delete-inbound-file', params);
  }
}
