import repository from "@/api/modules/Logistics/Dispatches";
import masterdata from "@/api/modules/MasterData/index";
import dropdown from '@/api/Main/DropDowns/index';

export default {
    state: {
        all_dispatches: [],
        vehicles:[],
        post_get_customers:[],
        get_packing_list:[],
        get_vehicle_cbm:[],
        get_item_cbm:[],
        store_dispatches:[],
        get_dispatches:[],
        get_dispatch_edit_info:[],
        update_dispatch_info:[],
        dispatch_date_from:'',
        dispatch_date_to:'',
        vehicle_id:'',

    },
    getters: {
        GET_ALL_DISPATCHES: (state) => state.all_dispatches,
        GET_VEHICLES: (state) => state.vehicles,
        POST_GET_CUSTOMERS: (state) => state.post_get_customers,
        GET_PACKING_LISTS:   (state) => state.get_packing_list,
        GET_VEHICLE_CBM: (state) => state.get_vehicle_cbm,
        GET_ITEM_CBM: (state) => state.get_item_cbm,
        GET_DISPATCHES: (state) => state.get_dispatches,
        GET_DISPATCH_EDIT_INFO: (state) => state.get_dispatch_edit_info,
    },
    actions: {
        getAllDispatches({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getalldispatches(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_DISPATCHES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getVehicle({ commit }, payload) {
            return new Promise((resolve, reject) => {
                dropdown.vehicles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('VEHICLES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPostCustomers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                masterdata.postgetcustomers(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('POST_CUSTOMERS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPackingList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                masterdata.getpackinglist(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PACKING_LISTS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getVehicleCBM({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getvehiclecbm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('VEHICLE_CBM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemCBM({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemcbm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                        commit('ITEM_CBM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeDispatches({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.storedispatches(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        ;
                        commit('STORE_DISPATCHES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDispatch({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getdispatch(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        ;
                        commit('DISPATCH', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDispatchEditInfo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getdispatcheditinfo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        ;
                        commit('DISPATCH_EDIT_INFO', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateDispatchInfo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updatedispatchinfo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_DISPATCH_INFO', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyDispatch({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroydispatch(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateDispatchStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updatedispatchstatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportdispatch({commit,state}){
            let payload = {
                date_from:state.dispatch_date_from,
                date_to:state.dispatch_date_to,
                vehicle_id:state.vehicle_id,
            }
            return new Promise((resolve, reject) => {
                repository.exportdispatch(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                    }
                }).catch((error) => {
                    reject(error)
                });
            });
        },
        dispatchGet({commit},payload){
            return new Promise((resolve, reject) => {
                repository.dispatchGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                    }
                }).catch((error) => {
                    reject(error)
                });
            });
        },
        dispatchPost({commit},payload){
            return new Promise((resolve, reject) => {
                repository.dispatchPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)

                    }
                }).catch((error) => {
                    reject(error)
                });
            });
        }
    },
    mutations: {
        ALL_DISPATCHES: (state, data) => { (state.all_dispatches = data) },
        VEHICLES: (state, data) => { (state.vehicles = data) },
        POST_CUSTOMERS: (state,data) => { (state.post_get_customers = data) },
        PACKING_LISTS: (state,data) => { (state.get_packing_list = data) },
        VEHICLE_CBM: (state,data) => { (state.get_vehicle_cbm = data) },
        ITEM_CBM: (state,data) => { (state.get_item_cbm = data) },
        STORE_DISPATCHES: (state,data) => { (state.store_dispatches = data) },
        DISPATCH: (state,data) => { (state.get_dispatches = data) },
        DISPATCH_EDIT_INFO: (state,data) => { (state.get_dispatch_edit_info = data) },
        UPDATE_DISPATCH_INFO: (state,data) => { (state.update_dispatch_info = data) },
        DISPATCH_DATE_FROM:(state,data) => {(state.dispatch_date_from = data)},
        DISPATCH_DATE_TO:(state,data) => {(state.dispatch_date_to = data)},
        DISPATCH_VEHICLES_ID:(state,data) => {(state.vehicle_id = data)}
    }

}
