import api from '../../../api'
export default {

    getcmdetails(params){
        return api.get('accounting/getCMDetails',{params})
    },
    creditmemoprint(id){
        return api.post(`accounting/creditmemo/print/${id}`)
    },
    approvecreditmemo(id,params){
        return api.post(`accounting/creditmemo/approve/${id}`,params)
    },
    rejectcreditmemo(params){
        return api.get(`accounting/rejectCreditMemo`,{params})
    },
    getallcm(params){
        return api.post(`accounting/creditmemo/getAll`,params)
    },
    showcm(id){
        return api.get(`accounting/creditmemo/${id}`)
    },
    postcm(params,config){
        return api.post(`accounting/creditmemo`,params,config)
    },
    updatecm(params,config){
        return api.post(`accounting/creditmemo/updateCreditMemo`,params,config)
    },
    deletecm(id){
        return api.delete(`accounting/creditmemo/${id}`)
    },
    cancelcm(id){
        return api.post(`accounting/creditmemo/cancel/${id}`)
    },
    getcustomersoas(id){
        return api.get(`accounting/creditmemo/getCustomerSoas/${id}`)
    },
    getcustomerpaymentdetailfiles(id){
        return api.get(`accounting/getCustomerPaymentDetailFiles/${id}`);
    },
    getcreditmemofiles(id){
        return api.get(`accounting/creditmemo/getCreditMemoFiles/${id}`);
    },
    getdebitmemofiles(id){
        return api.get(`accounting/debitmemo/getDebitMemoFiles/${id}`);
    },
    deletecustomerpaymentdetailfile(params){
        return api.post(`accounting/deleteCustomerPaymentDetailFile`,params);
    },
    deletecreditmemofile(params){
        return api.post(`accounting/creditmemo/deleteCreditMemoFile`,params);
    },
    deletedebitmemofile(params){
        return api.post(`accounting/debitmemo/deleteDebitMemoFile`,params);
    },
    printcreditmemo(id){
        return api.post(`accounting/creditmemo/print/${id}`);
    },
    getdmdetails(params){
        return api.get(`accounting/getDMDetails/`,params);
    },
    printdebitmemo(id){
        return api.post(`accounting/debitmemo/print/${id}`)
    },
    approvedm(id,params){
        return api.post(`accounting/debitmemo/approve/${id}`,params)
    },
    rejectdm(params){
        return api.get(`accounting/rejectDebitMemo`,{params})
    },
    getalldm(params){
        return api.post(`accounting/debitmemo/getAll`,params)
    },
    showdm(id){
        return api.get(`accounting/debitmemo/${id}`)
    },
    postdm(params,config){
        return api.post(`accounting/debitmemo`,params,config)
    },
    updatedm(params,config){
        return api.post(`accounting/debitmemo/updateDebitMemo`,params,config)
    },
    deletedm(id){
        return api.delete(`accounting/debitmemo/${id}`)
    },
    canceldm(id){
        return api.post(`accounting/debitmemo/cancel/${id}`)
    },
    getitemcreditmemo(id,params){
        return api.get(`reports/getItemCreditMemo/${id}`,{params})
    },
    getitemdebitmemo(id,params){
        return api.get(`reports/getItemDebitMemo/${id}`,{params})
    },
    getCreditMemoTypes(params){
        return api.get(`accounting/getCreditMemoTypes`,{params})
    },
    getDebitMemoTypes(params){
        return api.get(`accounting/getDebitMemoTypes`,{params})
    },
    getCreditMemoTypeStatus(params){
        return api.get(`accounting/getCreditMemoTypeStatus`,{params})
    },
    sendTechnicalReportEmail(params){
        return api.post(`accounting/sendTechnicalReportEmail`,params)
    },
    generateCreditMemoChina(params){
        return api.get(`accounting/generateCreditMemoChina`,{params})
    },
    addCreditMemoChina(params){
        return api.post(`accounting/addCreditMemoChina`,params)
    },
    getAllCreditMemoChina(params){
        return api.get(`accounting/getAllCreditMemoChina`,{params})
    },
    viewCreditMemoChina(params){
        return api.get(`accounting/viewCreditMemoChina`,{params})
    },
    updateCreditMemoChina(params){
        return api.post(`accounting/updateCreditMemoChina`,params)
    },
    confirmCreditMemoChina(params){
        return api.post(`accounting/confirmCreditMemoChina`,params)
    },
    postCreditMemoHistory(params){
        return api.post(`accounting/${params.url}`,params)
    },
    getCreditMemoHistory(params){
        return api.get(`accounting/${params.url}`,{params})
    },
    approveCreditMemoChina(params){
        return api.post(`accounting/approveCreditMemoChina`,params.data, params.config)
    },
    getCreditMemoChinaFiles(id){
        return api.get(`accounting/creditmemo/getCreditMemoChinaFiles/${id}`);
    },
    getCreditMemoTypesCardTotal(params){
        return api.get(`accounting/getCreditMemoTypesCardTotal`,{params})
    },
    disapprovedCreditMemoChina(id){
        return api.post(`accounting/disapprovedCreditMemoChina/${id}`)
    },
    showcmchina(id){
        return api.get(`accounting/creditmemochina/${id}`)
    },
    exportCreditMemoChina(params){
        return api.post(`accounting/exportCreditMemoChina`,params)
    },
    deleteCreditMemoChinaFile(params){
        return api.post(`accounting/deleteCreditMemoChinaFile`,params);
    },
}
