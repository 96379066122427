

import api from '../../../api'
export default {

    exportpendingsolistreport(params){
        return api.post(`/reports/pending-so/list/export`,params);
    },

}
