import api from '../../../api'
export default {

    getrequestwarehouse(){
        return api.get(`dropdown/stock/request/warehouse`);
    },
    getallrequest(params){
        return api.get(`inventory/stock/request`,{params});
    },
    getallstockrequestid(params){
        return api.get(`inventory/stock/getallstockrequestid`,{params});
    },
    getrequestitemsuggestion(params){
        return api.get(`inventory/stock/request/item-suggestions`,{params});
    },
    requestcreate(params){
        return api.post(`inventory/stock/request/create`,params);
    },
    getwarehouseitems(params){
        return api.post(`inventory/items/warehouse-items`,params);
    },
    requestapprove(id,params){
        return api.post(`inventory/stock/request/approve/${id}`,params);
    },
    requestcancel(id){
        return api.get(`inventory/stock/request/cancel/${id}`);
    },
    requestdetails(id,params){
        return api.get(`inventory/stock/request/${id}/details`,{params});
    },
    requestdestroy(id){
        return api.post(`inventory/stock/request/destroy/${id}`);
    },
    requestupdate(id,params){
        return api.post(`inventory/stock/request/update/${id}`,params);
    },
    stocktransfer(params){
        return api.get(`inventory/stock/transfer`,{params});
    },
    transferdetails(id,params){
        return api.get(`inventory/stock/transfer/${id}/details`,{params});
    },
    warehouseinventory(params){
        return api.get(`inventory/warehouse/inventory`,{params});
    },
    transferconfirmreceived(id,params,config){
        return api.post(`inventory/stock/transfer/confirm-received/${id}`,params,config);
    },
    requestcancelpartial(id){
        return api.get(`inventory/stock/request/cancel-partial/${id}`);
    },
    inventoryPost(params){
        return api.post('inventory/'+params.url,params)
    },

}
