import repository from '@/api/modules/MasterData'
import store from '@/store'
export default {
  state: {
    department: [],
    department_access_loaded: false,
  },
  getters: {
    GET_ALL_DEPARTMENT_ACCESS: state => state.department,
    GET_DEPARTMENT_ACCESS_LOADED: state => state.department_access_loaded,
  },
  actions: {
    getAllDepartmentAccess({ commit }) {
      repository.departmentaccesslist().then(response => {
        commit('DEPARTMENT_ACCESS_LOAD', response.data);
        commit('DEPARTMENT_ACCESS_LOADED', true)
      });
    },
    AccessControl({ commit }, payload) {
      const params = {
        code: payload.code,
        user_id: payload.user_id,
        admin: payload.payload
      };
      repository.accesscontrol(params)
    }
  },
  mutations: {
    DEPARTMENT_ACCESS_LOAD: (state, data) => {
      state.department = data;
    },
    DEPARTMENT_ACCESS_LOADED: (state, data) => {
      state.department_access_loaded = data;
    },
  },
};
