import repository from "@/api/modules/Operations/WarehousePersonnel/index";

export default {
    state: {
        DIALOG: {
            TRIGGER: false,
            ACTION: '',
        },
        warehouse_personnel: [],
        all_warehouse_personnel: [],
        update_warehouse_personnel:[],
        reload: false,
        all_preparer:[],
        all_checker:[],
        SELECTED:{}
    },
    getters: {
        WAREHOUSE_PERSONNEL: state => state.DIALOG,
        GET_WAREHOUSE_PERSONNEL: state => state.warehouse_personnel,
        GET_ALL_WAREHOUSE_PERSONNEL: state => state.all_warehouse_personnel,
        GET_RELOAD_WAREHOUSE_PERSONNEL: state => state.reload,
        GET_ALL_WAREHOUSE_PERSONNEL_PREPARER: state => state.all_preparer,
        GET_ALL_WAREHOUSE_PERSONNEL_CHECKER: state => state.all_checker,
        WAREHOUSE_PERSONNEL_GENERIC: state => state
        
    },
    actions: {
        addWarehousePersonnel({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.addwarehousepersonnel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ADD_WAREHOUSE_PERSONNEL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getAllWarehousepersonnel({commit},payload) {
            return await new Promise(async (resolve, reject) => {
                await repository.getallwarehousepersonnel(payload).then((response) => {
                    if (response.status == 200) {
                        commit('ALL_WAREHOUSE_PERSONNEL', response.data)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateWarehousepersonnel({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatewarehousepersonnel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_WAREHOUSE_PERSONNEL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteWarehousePersonnel({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletewarehousepersonnel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DELETE_WAREHOUSE_PERSONNEL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllwarehousepersonnelPreparer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallwarehousepersonnelpreparer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WAREHOUSE_PREPARER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllwarehousepersonnelChecker({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallwarehousepersonnelchecker(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WAREHOUSE_CHECKER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations:{
        WAREHOUSE_PERSONNEL_DIALOG: (state, data) => {
            state.DIALOG = data;
        },
        ADD_WAREHOUSE_PERSONNEL: (state, data) => {
            state.warehouse_personnel = data
        },
        ALL_WAREHOUSE_PERSONNEL: (state, data) => {
            state.all_warehouse_personnel = data
        },
        UPDATE_WAREHOUSE_PERSONNEL:(state, data) =>{
            state.update_warehouse_personnel = data
        },
        RELOAD_WAREHOUSE_PERSONNEL:(state, data) =>{
            state.reload = data
        },
        DELETE_WAREHOUSE_PERSONNEL: (state, data) => {
            state.update_warehouse_personnel = data
        },
        WAREHOUSE_PREPARER: (state, data) => {
            state.all_preparer = data
        },
        WAREHOUSE_CHECKER: (state, data) => {
            state.all_checker =data
        },
        SELECTED_PERSONEL: (state, data) => {
            state.SELECTED = data
        },
    }
}
