const state = {
    bank: {
        addEditBankDialog: false,
        dialogAction: 'add',
        successAddEdit: false,
        bankId: '',
    },
    customerPaymentDetail: {
        addEditCustPaymentDialog: false,
        dialogAction: 'add',
        successAddEdit: false,
        customerPaymentDetailId: '',
        custPaymentDetailDialog: false,
    },
    files: {
        filesViewerDialog: false
    },
    soaCustomerDetails: {
        soaCustPaymentDetailDialog: false,
        dialogAction: 'add',
        soaId: '',
        soaDetails: null,
        soaCustomerId: '',
        successUpdate: false,
    },
    payments: {
        menu: '',
    },
    customerSoaPaymentDetails: {
        custSoaPaymentDetailDialog: false
    },
    paymentType: {
        addEditPaymentTypeDialog: false,
        dialogAction: 'add',
        successAddEdit: false,
        paymentTypeId: '',
    },
    paymentVoucher:{
        addEditPaymentVoucher:false
    },
    vendorPaymentDetail: {
        addEditVendorPaymentDialog: false,
        dialogAction: 'add',
        successAddEdit: false,
        vendorPaymentDetailId: '',
        vendorPaymentDetailDialog: false,
    },
    custPaymentDetailEditChequeDate:false,
    custPaymentDetailReplaceCheque:false,
    custPaymentDetailBounceFromViewing:false,
};
const getters = {};
const actions = {};
const mutations = {
    bankShowAddEditDialog(state) {
        state.bank.addEditBankDialog = true;
    },
    bankCloseAddEditDialog(state) {
        state.bank.addEditBankDialog = false;
    },
    bankDialogAction(state, payload) {
        state.bank.dialogAction = payload;
    },
    bankClearAction(state) {
        state.bank.dialogAction = 'add';
    },
    bankSuccessAddEditTrue(state) {
        state.bank.successAddEdit = true;
    },
    bankSuccessAddEditFalse(state) {
        state.bank.successAddEdit = false;
    },
    bankSetId(state, payload) {
        state.bank.bankId = payload;
    },
    bankClearId(state) {
        state.bank.bankId = '';
    },
    custPaymentDetailShowAddEditDialog(state) {
        state.customerPaymentDetail.addEditCustPaymentDialog = true;
    },
    custPaymentDetailCloseAddEditDialog(state) {
        state.customerPaymentDetail.addEditCustPaymentDialog = false;
    },
    custPaymentDetailSuccessTrue(state) {
        state.customerPaymentDetail.successAddEdit = true;
    },
    custPaymentDetailSuccessFalse(state) {
        state.customerPaymentDetail.successAddEdit = false;
    },
    custPaymentDetailEditDialogAction(state) {
        state.customerPaymentDetail.dialogAction = 'view';
    },
    custPaymentDetailEditDialogAction(state) {
        state.customerPaymentDetail.dialogAction = 'edit';
    },
    custPaymentDetailClearDialogAction(state) {
        state.customerPaymentDetail.dialogAction = 'add';
    },
    custPaymentDetailSetId(state, payload) {
        state.customerPaymentDetail.customerPaymentDetailId = payload;
    },
    custPaymentDetailClearId(state) {
        state.customerPaymentDetail.customerPaymentDetailId = '';
    },
    custPaymentDetailShow(state) {
        state.customerPaymentDetail.custPaymentDetailDialog = true;
    },
    custPaymentDetailClose(state) {
        state.customerPaymentDetail.custPaymentDetailDialog = false;
    },
    filesShow(state) {
        state.files.filesViewerDialog = true;
    },
    filesClose(state) {
        state.files.filesViewerDialog = false;
    },
    soaShowCustDetailAddEditDialog(state) {
        state.soaCustomerDetails.soaCustPaymentDetailDialog = true;
    },
    soaCloseCustDetailAddEditDialog(state) {
        state.soaCustomerDetails.soaCustPaymentDetailDialog = false;
    },
    soaSetId(state, payload) {
        state.soaCustomerDetails.soaId = payload;
    },
    soaClearId(state) {
        state.soaCustomerDetails.soaId = '';
    },
    soaCustomerIdSetId(state, payload) {
        state.soaCustomerDetails.soaCustomerId = payload;
    },
    soaCustomerIdClearId(state) {
        state.soaCustomerDetails.soaCustomerId = '';
    },
    soaCustomerSetDetails(state, payload) {
        state.soaCustomerDetails.soaDetails = payload;
    },
    soaCustomerClearDetails(state) {
        state.soaCustomerDetails.soaDetails = null;
    },
    soaCustomerPaymentSuccessTrue(state) {
        state.soaCustomerDetails.successUpdate = true;
    },
    soaCustomerPaymentSuccessFalse(state) {
        state.soaCustomerDetails.successUpdate = null;
    },
    setPaymentsMenu(state, payload) {
        state.payments.menu = payload;
    },
    clearPaymentsMenu(state) {
        state.payments.menu = '';
    },
    custSoaPaymentDetailShow(state) {
        state.customerSoaPaymentDetails.custSoaPaymentDetailDialog = true;
    },
    custSoaPaymentDetailClose(state) {
        state.customerSoaPaymentDetails.custSoaPaymentDetailDialog = false;
    },
    paymentTypeShowAddEditDialog(state) {
        state.paymentType.addEditPaymentTypeDialog = true;
    },
    paymentTypeCloseAddEditDialog(state) {
        state.paymentType.addEditPaymentTypeDialog = false;
    },
    paymentTypeDialogAction(state, payload) {
        state.paymentType.dialogAction = payload;
    },
    paymentTypeClearAction(state) {
        state.paymentType.dialogAction = 'add';
    },
    paymentTypeSuccessAddEditTrue(state) {
        state.paymentType.successAddEdit = true;
    },
    paymentTypeSuccessAddEditFalse(state) {
        state.paymentType.successAddEdit = false;
    },
    paymentTypeSetId(state, payload) {
        state.paymentType.paymentTypeId = payload;
    },
    paymentTypeClearId(state) {
        state.paymentType.paymentTypeId = '';
    },
    paymentVoucherAddEditTrue(state) {
        state.paymentVoucher.addEditPaymentVoucher = true;
    },
    paymentVoucherAddEditFalse(state) {
        state.paymentVoucher.addEditPaymentVoucher = false;
    },
    vendorPaymentDetailShowAddEditDialog(state) {
        state.vendorPaymentDetail.addEditVendorPaymentDialog = true;
    },
    vendorPaymentDetailCloseAddEditDialog(state) {
        state.vendorPaymentDetail.addEditVendorPaymentDialog = false;
    },
    vendorPaymentDetailSuccessTrue(state) {
        state.vendorPaymentDetail.successAddEdit = true;
    },
    vendorPaymentDetailSuccessFalse(state) {
        state.vendorPaymentDetail.successAddEdit = false;
    },
    vendorPaymentDetailEditDialogAction(state) {
        state.vendorPaymentDetail.dialogAction = 'view';
    },
    vendorPaymentDetailEditDialogAction(state) {
        state.vendorPaymentDetail.dialogAction = 'edit';
    },
    vendorPaymentDetailClearDialogAction(state) {
        state.vendorPaymentDetail.dialogAction = 'add';
    },
    vendorPaymentDetailSetId(state, payload) {
        state.vendorPaymentDetail.vendorPaymentDetailId = payload;
    },
    vendorPaymentDetailClearId(state) {
        state.vendorPaymentDetail.vendorPaymentDetailId = '';
    },
    vendorPaymentDetailShow(state) {
        state.vendorPaymentDetail.vendorPaymentDetailDialog = true;
    },
    vendorPaymentDetailClose(state) {
        state.vendorPaymentDetail.vendorPaymentDetailDialog = false;
    },
    custPaymentDetailEditChequeDate(state,payload) {
        state.custPaymentDetailEditChequeDate = payload;
    },
    custPaymentDetailReplaceCheque(state,payload) {
        state.custPaymentDetailReplaceCheque = payload;
    },
    custPaymentDetailBounceFromViewing(state,payload) {
        state.custPaymentDetailBounceFromViewing = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
