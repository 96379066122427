import api from '../../../api'
export default {

    poststatementofaccounts(params){
        return api.post('soa/statementOfAccounts',params);
    },
    getsoa(id){
        return api.get(`soa/getSoa/${id}`);
    },
    updatesoa(id,params){
        return api.put(`soa/statementOfAccounts/${id}`,params);
    },
    getplbycustomer(id,params){
        return api.get(`packing-lists/getPLByCustomer/${id}`,{params});
    },
    getdebitmemobycustomer(id,params){
        return api.get(`accounting/debitmemo/getDebitMemoByCustomer/${id}`,{params});
    },
    getcreditmemobycustomer(id,params){
        return api.get(`accounting/creditmemo/getCreditMemoByCustomer/${id}`,{params});
    },
    getcustomerpaymentlist(params){
        return api.get(`accounting/getCustomerPaymentList/${params.customer_id}`,{params});
    },
    getallsoa(params){
        return api.post(`soa/statementOfAccounts/getAll`,params);
    },
    deletesoa(id){
        return api.delete(`soa/statementOfAccounts/${id}`);
    },
    updatesoastatus(id,params){
        return api.post(`soa/updateStatementOfAccountStatus/${id}`,params);
    },
    getsoapayments(id){
        return api.get(`accounting/getSoaPayments/${id}`)
    },
    getsoaplbyid(params){
        return api.get(`accounting/soaGetPlById`,{params})
    },
    getSoaPerPeriod(params){
        return api.get(`accounting/getSoaPerPeriod`,{params})
    },
    getSoaByPaymentDetailId(params){
        return api.get(`accounting/getSoaByPaymentDetailId`,{params})
    },
    allocateRemainingBalance(params){
        return api.post(`accounting/allocateRemainingBalance`,params);
    },
    getPaymentAllocationBySoaId(params){
        return api.get(`accounting/getPaymentAllocationBySoaId`,{params})
    },
    cancelSelectedPayment(params){
        return api.delete(`accounting/payments/${params.id}`,{params})
    },
    cancelCustomerPaymentAllocations(params){
        return api.post(`accounting/cancelCustomerPaymentAllocations`,params)
    },
    getTermsByCustomer(params){
        return api.get(`accounting/getTermsByCustomer`,{params})
    },
    getSoasByCustomerId(params){
        return api.get(`accounting/getSoasByCustomerId`,{params})
    },
    assignUnidentifiedPayment(params){
        return api.post(`accounting/assignUnidentifiedPayment`,params)
    },
    getSoaCardDetails(params){
        return api.get(`accounting/getSoaCardDetails`,{params})
    },
    exportPaymentDetailsPerCustomer(params){
        return api.get(`soa/statementOfAccounts/exportPaymentDetailsPerCustomer`,{params});
    },
    printSummarySOA(params){
        return api.post(`soa/printSummarySOA`,params);
    },
    confirmBadDebt(params){
        return api.post(`soa/confirmBadDebt`,params);
    },


}
