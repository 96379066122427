import repository from '@/api/modules/MasterData'
import store from '@/store'
export default {
    state: {
        departmentroles: [],
        selected_user_role: [],
        has_access: [],
        role_access: [],
        selected_users_details: {
            id: '',
            name: ''
        },
        submit_access: false,
        role_loaded: true,
        user_costing: false,
        isRoles:false,
        job_title_id: ''
    },
    getters: {
        GET_ALL_DEPARTMENT_ROLES: state => state.departmentroles,
        GET_HAS_ACCESS: state => state.has_access,
        SELECTED_USER_ROLE: state => state.selected_user_role,
        GET_ROLE_ACCESS: state => state.role_access,
        GET_USER_DETAILS: state => state.selected_users_details,
        GET_SUBMIT_SUCCESS: state => state.submit_access,
        GET_ROLE_LOADED: state => state.role_loaded,
        GET_USER_COSTING: state => state.user_costing,
        GET_IS_ROLES: state => state.isRoles,
        GET_JOB_TITLE_ID: state => state.job_title_id,
    },
    actions: {
        getAllDepartmentroles({ commit },payload) {
            repository.departmentroles(payload).then(response => {

                commit('DEPARTMENT_ROLES_LOAD', response.data);

            })
        },
        getAccessControl({ commit }, payload) {
            repository
                .getAccessControl(payload)
                .then(response => {
                    commit('HAS_ACCESS', response.data);
                })
                .catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async getRolesAccess({ commit }, payload) {
            commit('ROLE_LOADED', true);
            await repository.roleaccess(payload).then(response => {

                commit('ROLE_ACCESS_LOAD', response.data);
                commit('ROLE_LOADED', false);
            })
        },
        submitUserAccessControl({ commit }, payload) {

            return new Promise((resolve, reject) => {
                repository.addAccessControl(payload)
                    .then(({ data, status }) => {
                        let userID = payload.switch_access_child[0].user_id
                        let is_active = {
                            is_active: true
                        }
                        store.dispatch('Send_to_per_User', { userID: userID, data: is_active });
                        commit('SUBMIT_SUCCESS', true);
                        if (status == 200) {
                            resolve(data);
                        }


                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        getAllManagers({ commit }) {
            return new Promise((resolve, reject) => {
                repository.managers().then(response => {
                    resolve(response)
                    commit('MANAGERS_LOAD', response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },
        getUserCosting({ commit }) {
            return new Promise((resolve, reject) => {
                repository.usercosting().then(response => {
                    resolve(response.data)
                    commit('USER_COSTING', response.data === 1 ? true : false)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        ROLE_LOADED: (state, data) => {
            (state.role_loaded = data)
        },
        DEPARTMENT_ROLES_LOAD: (state, data) => {
            state.departmentroles = data;
        },
        HAS_ACCESS: (state, data) => {
            state.has_access = data;
        },
        SELECTED_USER_ROLE: (state, data) => {
            (state.selected_user_role = data.item),
                (state.selected_users_details = {
                    id: data.id,
                    name: data.users_name,
                    department_id: data.department_id,
                });
        },
        ROLE_ACCESS_LOAD: (state, data) => {
            state.role_access = data;
        },
        SUBMIT_SUCCESS: (state, data) => {
            state.submit_access = data;
        },
        USER_COSTING: (state, data) => {
            state.user_costing = data
        },
        IS_ROLES: (state, data) => {
            (state.isRoles = data)
        },
        JOB_TITLE_ID: (state, data) => {
            (state.job_title_id = data)
        },
    },
};
