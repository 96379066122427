<template>
    <v-app>
        <navbar v-if='isFullScreen()'></navbar>
        <ChangePasswordView></ChangePasswordView>
        <router-view></router-view>
    </v-app>
</template>
<script>
import navbar from './layouts/nav.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ChangePasswordView from '@/views/main/layouts/dialogs/Others/ChangePasswordView.vue';

export default {
    components: {
        navbar,
        ChangePasswordView,
    },
    data() {
        return {
            components: '',
        };
    },
    async mounted() {
        this.$store.dispatch('credentials');
    },
    computed: {
        ...mapGetters(['USERACCOUNT_LOAD', 'GET_ALL_USERS']),
    },
    methods: {
        getBirthdayCelebrants() {
            let logged_times = JSON.parse(localStorage.getItem('logged_times'));
            let celebrants = Object.values(
                this.GET_EMPLOYEE_BIRTHDAY_CELEBRANTS
            ).map((obj) => obj);
            let filtered = celebrants.filter((e) => {
                const birthDate = moment(e.birth_date, 'YYYY-MM-DD');
                return birthDate.format('MM-DD') === moment().format('MM-DD');
            });
            let seen_count = JSON.parse(localStorage.getItem('seen_today'));
            celebrants = filtered;
            if (celebrants.length == 0 || celebrants == []) {
                return false;
            } else {
                localStorage.setItem(
                    'seen_today',
                    seen_count ? seen_count + 1 : 1
                );
                if (
                    logged_times == 1 &&
                    JSON.parse(localStorage.getItem('seen_today')) == 1
                ) {
                    this.$store.commit('CELEBRANTS_DIALOG_MUTATE', true);
                    this.components = BirthdayCelebrantsViewVue;
                    var self = this;
                    setTimeout(function () {
                        self.$store.commit('CELEBRANTS_DIALOG_MUTATE', false);
                    }, 8000);
                }
            }
        },
        isFullScreen() {
            return true;
        },
    },
};
</script>
<style scoped>
#app {
    background-color: #ffffff !important;
}

.image_grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px;
}

.img_item {
    -ms-flex: 25%;
    flex: 25%;
    max-width: 100%;
    padding: 0 4px;
}

.img_item img {
    margin-top: 8px;
    vertical-align: middle;
    width: 90%;
}

.img-bg {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 80px;
}

div.img-bg img {
    width: 90%;

    border-radius: 50px;
}
</style>
