import api from '../../../api'
export default {

    getPackingListHistory(params){
        return api.get(`/accounting/`+params.url,{params})
    },
    postPackingListHistory(params){
        return api.post(`/accounting/`+params.url,params)
    },
}
