import api from '../../../api'
export default {

    getallwarehousetransfers(params){
        return api.get(`inventory/getAllWarehouseTransfers`,params);
    },
    getstocktransfer(id){
        return api.get(`inventory/getStockTransfer/${id}`);
    },
    getsrfnumlist(){
        return api.get('inventory/getSrfNumList');
    },
    storestocktransfer(params){
        return api.post('inventory/postStockTransfer',params);
    },
    destroystocktransfer(params){
        return api.delete(`inventory/deleteStockTransfer/${params}`);
    },
    updatestocktransfer(id,params){
        return api.put(`inventory/postUpdateStockTransfer/${id}`,params);
    },
    approvestocktransfer(id){
        return api.post(`inventory/approveStockTransfer`,{id});
    },
    getwarehouseitemsinline(params){
        return api.post(`inventory/items/warehouse-stocks/inline`,params);
    },
    
}
