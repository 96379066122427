import repository from "@/api/modules/Operations/WarehouseUser";

export default {
    state:{
        ITEMS:[],
        DIALOG:false,
        SELECTED:null,
        TAB:0,
        ACTION:{
            FILTERS:{},
            UPDATED:false
        },
        REFRESH:false,
        WAREHOUSES:[]
    },
    getters:{
        WAREHOUSE_USER: (state) => state
    },
    actions: {
        async warehouseUser({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.warehouseUser(payload).then((response) => {
                    if (response.status == 200) {
                        commit('WAREHOUSE_USER',response.data)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },

    mutations: {
        WAREHOUSE_USER: (state, data)=>{
            state.ITEMS = data
        },
        WAREHOUSE_USER_DIALOG:(state,data)=>{
            state.DIALOG = data
        },
        WAREHOUSE_USER_SELECTED:(state,data)=>{
            state.SELECTED = data
        },
        WAREHOUSE_USER_UPDATED:(state,data)=>{
            state.UPDATED = data
        },
        WAREHOUSE_USER_WAREHOSUES:(state,data)=>{
            state.WAREHOUSES = data
        },
        WAREHOUSE_USER_REFRESH:(state,data)=>{
            state.REFRESH = data
        }
    }
}
