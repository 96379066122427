import api from '../../../api'
export default{

    reports(params){
        return api.get(`/reports/`+params.url,{params})
    },

    reportexpansion(params){
        return api(`/${params.expansion_url}`,{params})
    },

    reportscharts(params){
      return api.get(`/reports/` + params.chart_url + '/' + params.chart + '-chart',{params})
    },

    salesreportspost(params){
      return api.post(`/reports/`+params.url,params)
    },

    reportspost(params){
      return api.post(`/reports/`+params.url,params)
    },

}
