import repository from '@/api/credentials'

const routes = [{
    path: '/sales/stocks-inquiry/:id',
    name: 'stocksinquiry',
    props: true,
    component: () => import('@/views/main/modules/Sales/StocksInquiry/StocksInquiryGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stocks Inquiry',
    },
}];

export default routes;
