import api from '../../../api'
export default {
  getalloutbound (params) {
    return api.get('outbound/getAll', { params: params });
  },
  viewoutbound(params) {
    return api.get(`/outbound/outbound/${params}`);
  },
  addnewoutbound(params) {
    return api.post('/outbound/outbound', params);
  },
  editoutbound(params) {
    return api.put('/outbound/edit-outbound', params);
  },
  receiveoutbound(params) {
    return api.post(`/outbound/receiveOutbound/${params}`);
  },
  approveoutbound(params) {
    return api.post(`/outbound/approveOutbound/${params}`);
  },
  rejectoutbound(params) {
    return api.post(`/outbound/rejectOutbound/${params}`);
  },
  deleteoutbound(params) {
    return api.delete(`/outbound/delete-outbound/${params}`);
  },
  exportoutbound(params) {
    return api.post('outbound/exportOutbound', params);
  }
}
