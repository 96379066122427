import repository from "@/api/modules/Sales/SalesQuotations/index";

export default {
   state:{
        // stock_adjustment:[],
        view_sales_quotation : [],
        salesquotation_list  : [],
        salesquotation_total: 0,
        salesquotation_loaded:false,
        salesquotation_reload:false,

   },
    getters:{
        // GET_ALL_STOCK:(state)               => state.stock_adjustment,
        GET_VIEW_SALES_QUOTATION:(state)               => state.view_sales_quotation,
        GET_SALES_QOUTATION_LIST:(state)        => state.salesquotation_list,
        GET_SALES_QOUTATION_TOTAL:(state)        => state.salesquotation_total,
        GET_SALES_QOUTATION_LOADED:(state)        => state.salesquotation_loaded,
        GET_SALES_QUOTATION_RELOAD_TABLE:(state)  => state.salesquotation_reload,
    },
    actions:{
        checkCreditLimit({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.checkcreditlimit(payload).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        async convertToSalesOrder({commit},payload){
            return await new Promise((resolve,reject)=>{
                repository.convertosalesorder(payload).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        checkCreditTerms({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.checkcreditterms(payload.params).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesQuotationsGetAll({commit,state},payload){
            return new Promise((resolve,reject)=>{
                repository.salesquotationsgetall(payload).then((response)=>{
                    state.salesquotation_loaded = true
                    state.salesquotation_total = response.data.total
                    resolve(response)
                    commit('SALES_QOUTATION_LIST',response.data.data)
                })
            })
        },
        salesQuotations({commit},payload){
            return new Promise((resolve,reject)=>{
                    repository.salesquotation(payload).then((response)=>{
                        resolve(response)
                    }).catch((e)=>{
                        reject(e)
                    })
            });
        },
        storeSalesQuotations({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.storesalesquotations(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('SALES_QUOTATION_RELOAD_TABLE',true)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesQuotationsUpdate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesquotationsupdate(payload).then((response)=>{
                    resolve(response)
                }).catch((e)=>{
                    reject(e);
                })
            })
        },
        salesQuotationsDelete({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesquotationsdelete(payload).then((response)=>{
                    resolve(response)
                }).catch((e)=>{
                    reject(e);
                })
            })
        },
        viewSalesQuotation({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.viewsalesquotation(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('VIEW_SALES_QUOTATION',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        exportSalesQuotation({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.exportsalesquotation(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        salesquotationGet({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesquotationGet(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        }
    },
    mutations:{
        // STOCK_LOAD    : (state,data) => {(state.stock_adjustment = data)},
        VIEW_SALES_QUOTATION    : (state,data) => {(state.view_sales_quotation = data)},
        SALES_QOUTATION_LIST    : (state,data) => {(state.salesquotation_list = data)},
        // SALES_QOUTATION_TOTAL   : (state,data) => {state.salesquotation_total = data}
        SALES_QUOTATION_RELOAD_TABLE:(state,data)=>{(state.salesquotation_reload = data)}
    }

}
