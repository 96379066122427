import repository from '@/api/modules/MasterData'

export default {
  state: {
    vehicles: [],
    selected_vehicles: [],
    new_vehicles: false,
    vehicle_loaded: false,
    vehicle_types:[],
    vehicles_type: [],
    selected_vehicles_type: [],
    new_vehicles_type: false,
    vehicle_loaded_type: false,
  },
  getters: {
    GET_VEHICLES_LIST: state => state.vehicles,
    GET_SELECTED_VEHICLE: state => state.selected_vehicles,
    NEW_VEHICLES: state => state.new_vehicles,
    GET_VEHICLES_LOADED: state => state.vehicle_loaded,
    GET_VEHICLES_TYPE_LIST: state => state.vehicles_type,
    GET_SELECTED_VEHICLE_TYPE: state => state.selected_vehicles,
    NEW_VEHICLES_TYPE: state => state.new_vehicles,
    GET_VEHICLES_LOADED_TYPE: state => state.vehicle_loaded,
    GET_VEHICLE_TYPES: state => state.vehicle_types,
  },
  actions: {
    getVehicles({ commit },payload) {
      new Promise((resolve,reject)=>{
        repository.vehicleslist(payload).then(response => {
          commit('VEHICLES_LIST', response.data);
          commit('VEHICLES_LOADED', true)
        });
      }).catch(error=>{
        reject(error);
      })

    },
    addNewVehicles({ commit }, payload) {
      repository.addvehicle(payload).then(() => {
        commit('NEW_VEHICLES', true);
      })
    },
    UpdateVehicles({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        code: payload.payload.code,
        brand: payload.payload.brand,
        type: payload.payload.type,
        plate_number: payload.payload.plate_number,
        lengths: payload.payload.lengths,
        width: payload.payload.width,
        height: payload.payload.height,
        cbm: payload.payload.cbm,
        tpl:payload.payload.tpl
      };
      repository.updatevehicles(params).then(response => {
        commit('NEW_VEHICLES', true);
      })
    },
    deletevehicles({ commit }, payload) {
      repository.deletevehicles(payload).then(response => {
        commit('DELETED_VEHICLES', payload);
      })
    },
    getVehiclesType({ commit },payload) {
      new Promise((resolve,reject)=>{
        repository.vehicleslisttype(payload).then(response => {
          commit('VEHICLES_TYPE_LIST', response.data);
          commit('VEHICLES_LOADED_TYPE', true)
        });
      }).catch(error=>{
        reject(error);
      })

    },
    addNewVehiclesType({ commit }, payload) {
      repository.addvehicletype(payload).then(() => {
        commit('NEW_VEHICLES_TYPE', true);
      })
    },
    UpdateVehiclesType({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        code: payload.payload.code,
      };
      repository.updatevehiclestype(params).then(response => {
        commit('NEW_VEHICLES_TYPE', true);
      })
    },
    deletevehiclestype({ commit }, payload) {
      repository.deletevehiclestype(payload).then(response => {
        commit('DELETED_VEHICLES_TYPE', payload);
      })
    },
    getAllVehicleTypes({ commit }, payload) {
      repository.getvehicletypes(payload).then(response => {
        commit('VEHICLE_TYPES', response.data);
      })
    },
  },
  mutations: {
    VEHICLES_LIST: (state, data) => {
      state.vehicles = data;
    },
    NEW_VEHICLES: (state, data) => {
      state.new_vehicles = data;
    },
    SELECTED_VEHICLE: (state, data) => {
      state.selected_vehicles = data;
    },
    EDITED_VEHICLES: (state, payload) => {
      const index = state.vehicles.findIndex(t => t.id == payload.id);
      state.vehicles.splice(index, 1, payload);
    },
    DELETED_VEHICLES: (state, id) => {
      state.vehicles = state.vehicles.filter(vehicles => vehicles.id !== id);
    },
    VEHICLES_LOADED: (state, data) => {
      state.vehicle_loaded = data;
    },

    VEHICLES_TYPE_LIST: (state, data) => {
      state.vehicles_type = data;
    },
    NEW_VEHICLES_TYPE: (state, data) => {
      state.new_vehicles_type = data;
    },
    SELECTED_VEHICLE_TYPE: (state, data) => {
      state.selected_vehicles_type = data;
    },
    EDITED_VEHICLES_TYPE: (state, payload) => {
      const index = state.vehicles_type.findIndex(t => t.id == payload.id);
      state.vehicles_type.splice(index, 1, payload);
    },
    DELETED_VEHICLES_TYPE: (state, id) => {
      state.vehicles_type = state.vehicles_type.filter(vehicles => vehicles.id !== id);
    },
    VEHICLES_LOADED_TYPE: (state, data) => {
      state.vehicle_loaded = data;
    },

    VEHICLE_TYPES: (state, data) => {
      state.vehicle_types = data;
    },
  },
};
