import repository from "@/api/modules/Operations/StockRequest";

export default {
    state: {
        request_warehouse:[],
        all_request:[],
        request_item_suggestion: [],
        warehouse_items:[],
        request_details:[],
        stock_transfer:[],
        transfer_details:[],
        warehouse_inventory:[],
        request_cancel_partial:[],
        stock_request_id:[]
    },
    getters: {
        GET_REQUEST_WAREHOUSE: (state) => state.request_warehouse,
        GET_ALL_REQUEST: (state) => state.all_request,
        GET_REQUEST_ITEM_SUGGESTION: (state) => state.request_item_suggestion,
        GET_WAREHOUSE_ITEMS: (state) => state.warehouse_items,
        GET_REQUEST_DETAILS: (state) => state.request_details,
        GET_STOCK_TRANSFER: (state) => state.stock_transfer,
        GET_TRANSFER_DETAILS: (state) => state.transfer_details,
        GET_WAREHOUSE_INVENTORY: (state) => state.warehouse_inventory,
        GET_REQUEST_CANCEL_PARTIAL: (state) => state.request_cancel_partial,
        GET_STOCK_REQUEST_ID: (state) => state.stock_request_id,
    },
    actions: {
        getRequestWarehouse({commit}) {
            return new Promise((resolve, reject) => {
                repository.getrequestwarehouse().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REQUEST_WAREHOUSE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllRequest({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallrequest(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_REQUEST', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllStockRequestId({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallstockrequestid(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_STOCK_REQUEST_ID', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRequestItemSuggestion({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getrequestitemsuggestion(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REQUEST_ITEM_SUGGESTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestCreate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestcreate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getWarehouseItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getwarehouseitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WAREHOUSE_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestApprove({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestapprove(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestCancel({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestcancel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestdetails(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REQUEST_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestDestroy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestdestroy(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestUpdate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestupdate(payload.id,payload.stockRequest).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        stockTransfer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.stocktransfer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STOCK_TRANSFER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferdetails(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TRANSFER_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        warehouseInventory({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.warehouseinventory(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WAREHOUSE_INVENTORY', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferConfirmReceived({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferconfirmreceived(payload.id,payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        requestCancelPartial({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.requestcancelpartial(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REQUEST_CANCEL_PARTIAL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        inventoryPost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.inventoryPost(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        REQUEST_WAREHOUSE: (state, data) => { (state.request_warehouse = data) },
        ALL_REQUEST: (state, data) => { (state.all_request = data) },
        REQUEST_ITEM_SUGGESTION: (state, data) => { (state.request_item_suggestion = data) },
        WAREHOUSE_ITEMS: (state, data) => { (state.warehouse_items = data) },
        REQUEST_DETAILS: (state, data) => { (state.request_details = data) },
        STOCK_TRANSFER: (state, data) => { (state.stock_transfer = data) },
        TRANSFER_DETAILS: (state, data) => { (state.transfer_details = data) },
        WAREHOUSE_INVENTORY: (state, data) => { (state.warehouse_inventory = data) },
        REQUEST_CANCEL_PARTIAL: (state, data) => { (state.request_cancel_partial = data) },
        ALL_STOCK_REQUEST_ID: (state, data) => { (state.stock_request_id = data)},
    }

}
