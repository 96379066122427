const state = {
    scEstDialog: false,
    rfDialogWithEst: false,
    sparePartsDialog: false,
    techRfEstDialog: false,
    scPartsOrderDialog: false,
    conversationDialog: false,
    searchByPartNumDialog: false,
    repairFormDetailsDialog: false,
    requestNewSparePartsDialog: false,
    showQrCodeDialog: false,
    deliveryReceiptDetailsDialog: false,
    baseCameraCaptureDialog: false,
    baseImageViewerDialog: false,
    filesViewerDialog: false,
    addBaklasDialog: false,
    baklasDetailsDialog: false,
    rfInquiryDialog: false,
    customerMessagesDialog: false,
    qrScannerDialog: false,
    locationDialog: false,
    scLocation: {
        createLocation: false,
        action: '',
        id: '',
    },
    exportDialog: false,
    technician: {
        id: '',
        addEditDialog: false,
        success: false,
    },
    callCenter: {
        ccId: '',
        success: false,
        detailsDialog: false
    },
    salesQuotation: {
        sqId: '',
        detailsDialog: false
    },
    rfEditDialog: false,
    scLocationWarehouses:{},
};
const getters = {
    SC_PROCESSING:(state) => state,
};
const actions = {};
const mutations = {
    showScEstDialog(state) {
        state.scEstDialog = true;
    },
    closeScEstDialog(state) {
        state.scEstDialog = false;
    },
    showRfDialogWithEst(state) {
        state.rfDialogWithEst = true;
    },
    closeRfDialogWithEst(state) {
        state.rfDialogWithEst = false;
    },
    showSparePartsDialog(state) {
        state.sparePartsDialog = true;
    },
    closeSparePartsDialog(state) {
        state.sparePartsDialog = false;
    },
    showTechRfEstDialog(state) {
        state.techRfEstDialog = true;
    },
    closeTechRfEstDialog(state) {
        state.techRfEstDialog = false;
    },
    showScPartsOrderDialog(state) {
        state.scPartsOrderDialog = true;
    },
    closeScPartsOrderDialog(state) {
        state.scPartsOrderDialog = false;
    },
    showConversationDialog(state) {
        state.conversationDialog = true;
    },
    closeConversationDialog(state) {
        state.conversationDialog = false;
    },
    showSearchByPartNumDialog(state) {
        state.searchByPartNumDialog = true;
    },
    closeSearchByPartNumDialog(state) {
        state.searchByPartNumDialog = false;
    },
    showRepairFormDetailsDialog(state) {
        state.repairFormDetailsDialog = true;
    },
    closeRepairFormDetailsDialog(state) {
        state.repairFormDetailsDialog = false;
    },
    showRequestNewSparePartsDialog(state) {
        state.requestNewSparePartsDialog = true;
    },
    closeRequestNewSparePartsDialog(state) {
        state.requestNewSparePartsDialog = false;
    },
    showShowQrCodeDialog(state) {
        state.showQrCodeDialog = true;
    },
    closeShowQrCodeDialog(state) {
        state.showQrCodeDialog = false;
    },
    showDeliveryReceiptDetailsDialog(state) {
        state.deliveryReceiptDetailsDialog = true;
    },
    closeDeliveryReceiptDetailsDialog(state) {
        state.deliveryReceiptDetailsDialog = false;
    },
    showBaseCameraCaptureDialog(state) {
        state.baseCameraCaptureDialog = true;
    },
    closeBaseCameraCaptureDialog(state) {
        state.baseCameraCaptureDialog = false;
    },
    showBaseImageViewerDialog(state) {
        state.baseImageViewerDialog = true;
    },
    closeBaseImageViewerDialog(state) {
        state.baseImageViewerDialog = false;
    },
    showFilesViewerDialog(state) {
        state.filesViewerDialog = true;
    },
    closeFilesViewerDialog(state) {
        state.filesViewerDialog = false;
    },
    showAddBaklasDialog(state) {
        state.addBaklasDialog = true;
    },
    closeAddBaklasDialog(state) {
        state.addBaklasDialog = false;
    },
    showBaklasDetailsDialog(state) {
        state.baklasDetailsDialog = true;
    },
    closeBaklasDetailsDialog(state) {
        state.baklasDetailsDialog = false;
    },
    showRfInquiryDialog(state) {
        state.rfInquiryDialog = true;
    },
    closeRfInquiryDialog(state) {
        state.rfInquiryDialog = false;
    },
    showCustomerMessagesDialog(state) {
        state.customerMessagesDialog = true;
    },
    closeCustomerMessagesDialog(state) {
        state.customerMessagesDialog = false;
    },
    showQrScannerDialog(state) {
        state.qrScannerDialog = true;
    },
    closeQrScannerDialog(state) {
        state.qrScannerDialog = false;
    },
    scLocationShowAddEditDialog(state) {
        state.locationDialog = true;
    },
    scLocationCloseAddEditDialog(state) {
        state.locationDialog = false;
    },
    scLocationCreateLocationTrue(state) {
        state.scLocation.createLocation = true;
    },
    scLocationCreateLocationFalse(state) {
        state.scLocation.createLocation = false;
    },
    scLocationAddAction(state) {
        state.scLocation.action = 'add';
    },
    scLocationEditAction(state) {
        state.scLocation.action = 'edit';
    },
    scLocationViewAction(state) {
        state.scLocation.action = 'view';
    },
    scLocationClearAction(state) {
        state.scLocation.action = '';
    },
    scLocationGetId(state, id) {
        state.scLocation.id = id;
    },
    scLocationClearId(state) {
        state.scLocation.id = '';
    },
    showExportDialog(state) {
        state.exportDialog = true;
    },
    closeExportDialog(state) {
        state.exportDialog = false;
    },
    setTechnicianId(state, payload) {
        state.technician.id = payload;
    },
    unsetTechnicianId(state) {
        state.technician.id = '';
    },
    showTechnicianDialog(state) {
        state.technician.addEditDialog = true;
    },
    closeTechnicianDialog(state) {
        state.technician.addEditDialog = false;
    },
    successAddEditTechnician(state) {
        state.technician.success = true;
    },
    unsuccessAddEditTechnician(state) {
        state.technician.success = false;
    },
    successAddEditCustConversation(state) {
        state.callCenter.success = true;
    },
    unsuccessAddEditCustConversation(state) {
        state.callCenter.success = false;
    },
    showCcDialog(state) {
        state.callCenter.detailsDialog = true;
    },
    closeCcDialog(state) {
        state.callCenter.detailsDialog = false;
    },
    setCcId(state, payload) {
        state.callCenter.ccId = payload;
    },
    unsetCcId(state) {
        state.callCenter.ccId = '';
    },
    setSqId(state, payload) {
        state.salesQuotation.sqId = payload;
    },
    unsetSqId(state) {
        state.salesQuotation.sqId = '';
    },
    showSqDialog(state) {
        state.salesQuotation.detailsDialog = true;
    },
    closeSqDialog(state) {
        state.salesQuotation.detailsDialog = false;
    },
    showRfEditDialog(state) {
        state.rfEditDialog = true;
    },
    closeRfEditDialog(state) {
        state.rfEditDialog = false;
    },
    setLocationWarehouses(state, data) {
        state.scLocationWarehouses = data
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
