import api from '../../api'
export default {
passwordDefault(params){
      return api.post('system-settings/passworddefault',params);
    },
    GeneralSettings(params){
        return api.get('system-settings/settings');
    },
    getemailsettings(params){
        return api.get('/system-settings/email-settings',{params});
    },
    addmoduleemailsettings(params){
        return api.post('/system-settings/email-settings',params);
    },
    addNewEmailModule(params){
        return api.post('/system-settings/addNewEmailModule',params);
    },
}
