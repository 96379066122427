import repository from '@/api/Main/SystemSettings/GeneralSettings.js';
import store from '@/store';
import Swal from 'sweetalert2';
export default {
  state: {
    new_password_default:false,
    general_settings:{},
    email_settings: []
  },
  getters: {
    GET_NEW_PASSWORD_DEFAULT:(state)=>state.new_password_default,
    GET_GENERAL_SETTINGS:(state)=>state.general_settings,
    GET_EMAIL_SETTINGS:(state)=>state.email_settings,
  },
  actions: {
    passwordDefault({commit},payload){
      repository.passwordDefault(payload).then((response)=>{
        commit('NEW_PASSWORD_DEFAULT',true)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    GeneralSettings({commit}){
        return new Promise((resolve, reject) => {
            repository.GeneralSettings().then((response)=>{

              commit('GENERAL_SETTINGS',response.data);

              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    getEmailSettings({commit},payload){
        return new Promise((resolve, reject) => {
            repository.getemailsettings(payload).then((response)=>{
              resolve(response.data)
              commit('EMAIL_SETTINGS',response.data);

              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    addModuleEmailSettings({commit},payload){
        return new Promise((resolve, reject) => {
            repository.addmoduleemailsettings(payload).then((response)=>{
              resolve(response.data)
              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    addNewEmailModule({commit},payload){
        return new Promise((resolve, reject) => {
            repository.addNewEmailModule(payload).then((response)=>{
              resolve(response.data)
              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
  },
  mutations: {
    NEW_PASSWORD_DEFAULT:(state,data)=>{
        state.new_password_default = data
    },
    GENERAL_SETTINGS:(state,data)=>{
        state.general_settings = data
    },
    EMAIL_SETTINGS:(state,data)=>{
        state.email_settings = data
    }
  },
}
