import repository from '@/api/modules/Accounting/Banks'
import store from '@/store';
export default {
	state: {
	
	},
	getters: {
	
	},
	actions:{
		banksGet({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctBanksGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		banksPost({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctBanksPost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		
	},
}
