import repository from '@/api/modules/MasterData'
export default {
  state: {
    user: [],
    userTable: [],
    manager: [],
    selected_user: [],
    newuser: false,
    confirm_override_print_pl: false,
    confirm_override:[],
    user_loaded: false,
    error_user: false,


  },
  getters: {
    GET_ALL_USERS: state => state.user,
    GET_ALL_USERS_TABLE: state => state.userTable,
    GET_ALL_MANAGERS: state => state.manager,
    SELECTED_USER: state => state.selected_user,
    NEW_USER: state => state.newuser,
    GET_USERS_LOADED: state => state.user_loaded,
    GET_ERROR_USER: state => state.error_user,
    GET_CONFIRM_OVERRIDE: (state) => state.confirm_override,
    GET_CONFIRM_OVERRIDE_PRINT_PL: (state) => state.confirm_override_print_pl,


  },
  actions: {
    getAllManagers({ commit }) {
      return new Promise((resolve, reject) => {
        repository.managers().then(response => {
          resolve(response)
          commit('MANAGERS_LOAD', response.data);
        }).catch(error=>{
          reject(error)
        });
    })
    },
    checkIfManager({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.checkIfManager(payload).then(response => {
          resolve(response)
          commit('MANAGERS_LOAD', response.data);
        }).catch(error=>{
          reject(error)
        });
    })
    },
    userGetManagerAdmin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.usergetadmin(payload).then(response => {
          resolve(response)
        }).catch(error=>{
          reject(error)
        });
    })
    },
   async getAllUsers({ commit }) {
    return await new Promise((resolve,reject)=>{
      repository.userslist().then(response => {
        let res = response.data;
        resolve(response)
        commit('USERS_LOAD', res);
        commit('USERS_LOADED', true)
      }
      ).catch((err)=>{
        reject(err)
      });
    })
    },
   async getAllUsersTable({ commit }, payload) {
    return await new Promise((resolve,reject)=>{
      repository.userslistTable(payload).then(response => {
        let res = response.data;
        resolve(res.data)
        commit('USERS_LOAD_TABLE', res);
        commit('USERS_LOADED', false)
      }
      ).catch((err)=>{
        reject(err)
      });
    })
    },
    NewUser({ commit }, payload) {
      repository
        .newuser(payload)
        .then(response => {
          if (response.data.status == 422) {
            commit('ERROR_USER', true);
          } else {
            commit('NEW_USER', true);
          }
        })
        .catch(error => {});
    },
    EditUser({ commit }, payload) {
      repository.edituser(payload).then(response => {
          commit('NEW_USER', true)
      });
    },
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        repository
          .deleteuser(id)
          .then(({ data, status }) => {
            if (status == 200) {
              commit('REMOVE_USER', id);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    confirmOverridePrintPL({commit},payload) {
      return new Promise((resolve, reject) => {
          repository.confirmoverrideprintpl(payload).then((response) => {
              if (response.status == 200) {
                  resolve(response)
                  commit('CONFIRM_OVERRIDE_PRINT_PL',response.data)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    confirmOverride({commit},payload) {
      return new Promise((resolve, reject) => {
          repository.confirmoverride(payload).then((response) => {
              if (response.status == 200) {
                  resolve(response)
                  commit('CONFIRM_OVERRIDE',response.data)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    confirmOverrideSOA({commit},payload) {
      return new Promise((resolve, reject) => {
          repository.confirmoverridesoa(payload).then((response) => {
              if (response.status == 200) {
                  resolve(response)
                  commit('CONFIRM_OVERRIDE',response.data)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    postChangePassword({commit},payload) {
      return new Promise((resolve, reject) => {
          repository.postChangePassword(payload).then((response) => {
              if (response.status == 200) {
                  resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getUsers({commit},payload){
        return new Promise((resolve, reject) => {
            repository.getusers(payload).then((response) => {

            }).catch((error) => {
                reject(error)
            });
        })
    },
    currentUser({commit},payload){
        return new Promise((resolve, reject) => {
            repository.currentUser(payload).then((response) => {
                localStorage.setItem('userData', JSON.stringify(response.data));
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            });
        })
    }
  },
  mutations: {
    USERS_LOAD: (state, data) => {
      state.user = data;
    },
    USERS_LOAD_TABLE: (state, data) => {
      state.userTable = data;
    },
    NEW_USER: (state, data) => {
      state.newuser = data;
    },
    USERS_LOADED: (state, data) => {
      state.user_loaded = data
    },
    SELECTED_USER: (state, data) => {
      state.selected_user = data;
    },
    MANAGERS_LOAD: (state, data) => {
      state.manager = data;
    },
    EDIT_USER: (state, payload) => {
      const index = state.user.findIndex(t => t.id == payload.id)
      state.user.splice(index, 1, payload)
    },
    CONFIRM_OVERRIDE: (state, data) => {
      (state.confirm_override = data);
    },
    CONFIRM_OVERRIDE_PRINT_PL: (state, data) => {
      (state.confirm_override_print_pl = data);
    },
    REMOVE_USER: (state, id) =>
      (state.user = state.user.filter((users) => users.id !== id)),
    ERROR_USER: (state, data) => {
      state.error_user = data
    }
  },
};
