import api from '../../../api'
export default {
    get(params) {
        return api.get(`accounting/${params.url}`,{params})
    },
    show(params) {
        return api.get(`accounting/${params.url}/${params.id}`, {params})
    },
    save(params) {
        return api.post(`accounting/${params.url}`,params)
    },
    update(params) {
        return api.put(`accounting/${params.url}/${params.id}`, params)
    },
    delete(params) {
        return api.delete(`accounting/${params.url}/${params.id}`,params)
    },
}
