import api from '../../../api'
import axios from 'axios';

let cancelToken;
export default{

    warehouseReturnPost(params){

        if(!params.config){
            return api.post(`/warehouse-return/${params.url}`,params);
        }
        else{
            return api.post(`/warehouse-return/${params.url}`,params.data,params.config);
        }

    },
    warehouseReturnGet(params){
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source()
        return api.get(`/warehouse-return/${params.url}`,{params},{ cancelToken: cancelToken.token });
    },
}
