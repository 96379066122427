import repository from '@/api/modules/Accounting/AccountingUser'
import store from '@/store';
export default {
	state: {
		
	},
	getters: {
		
	},
	actions:{
		getAU({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctUserGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		postAU({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctUserPost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
	
	},
}
