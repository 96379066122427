import api from '../../../api'
export default {

    getalldispatches(params){
        return api.get('dispatches/getAll',{params});
    },
    getvehiclecbm(params){
        return api.get('dispatches/getVehicleCBM',{params});
    },
    getitemcbm(params){
        return api.get('dispatches/getItemCBM',{params})
    },
    storedispatches(params){
        return api.post('dispatches/dispatches',params);
    },
    getdispatch(params){
        return api.get(`dispatches/getDispatch/${params}`);
    },
    getdispatcheditinfo(params){
        return api.get(`dispatches/getDispatchEditInfo/${params}`);
    },
    updatedispatchinfo(params){
        return api.post(`dispatches/updateDispatchInfo`,params);
    },
    destroydispatch(params){
        return api.delete(`/dispatches/dispatches/${params}`);
    },
    updatedispatchstatus(payload){
        return api.post(`dispatches/updateDispatchStatus/${payload.id}`,{payload})
    },
    exportdispatch(params){
        return api.post(`dispatches/exportDispatch`,params);
    },
    dispatchGet(params){
        return api.get(`dispatches/${params.url}`,{params})
    },
    dispatchPost(params){
        return api.post(`dispatches/${params.url}`,params)
    }
}
