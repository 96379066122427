import api from '../../../api'
export default{

    plget(params){
      return api.get(`/packing-lists/`+params.url,{params})
    },
    plpost(params){
      return api.post(`/packing-lists/`+params.url,params)
    },
    plput(params){
      return api.put(`/packing-lists/`+params.url,params)
    },
    pldelete(params){
      return api.delete(`/packing-lists/`+params.url,params)
    },

}
