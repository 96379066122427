import api from '@/api/api'
export default {
    stockcardsGET(params){
        return api.get(params.url,{params});
    },
    stockcardsDelete(params){
        return api.delete(params.url,{params});
    },
    stockcardsPost(params){
        return api.post(params.url,{params});
    },
    stockcardsPut(params){
        return api.put(params.url,params);
    },
}
