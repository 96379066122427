import repository from '@/api/modules/MasterData'
import dropdown from '@/api/Main/DropDowns/index'
import swal from 'sweetalert2'
import store from '@/store'
export default {
  state: {
    vendor: [],
    vendor_type: [],
    selected_vendor: [],
    vendor_type_table: [],
    newvendor: false,
    newvendortype: false,
    totalcustomer: 0,
    counter: [],
    selected_vendorType: [],
    vendor_type_loader: false,
  },
  getters: {
    GET_ALL_VENDOR: state => state.vendor,
    GET_ALL_VENDOR_TYPE: state => state.vendor_type,
    GET_ALL_VENDOR_TYPE_TABLE: state => state.vendor_type_table,
    NEW_VENDOR: state => state.newvendor,
    NEW_vendortype: state => state.newvendortype,
    GET_EDIT_VENDORS: state => state.selected_vendor,
    GET_COUNTER_VENDOR: state => state.counter,
    GET_VENDOR_TYPE_LOADER: state => state.vendor_type_loader,
    SELECTED_VENDOR_TYPE: state => state.selected_vendorType,
  },
  actions: {
    addVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addvendor(payload)
          .then(response => {
            resolve(response.data);

          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    addVendorAddress({ commit }, payload) {
      repository
        .addvendoraddress(payload)
        .then(response => { })
        .catch(error => { });
    },
    editVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .editvendor(payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    DeleteVendor({ commit }, id) {
      return new Promise((resolve, reject) => {
        repository
          .deletevendor(id)
          .then(({ data, status }) => {
            if (status == 200) {

              resolve(data);
            }
          })
          .catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    getallvendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .vendor(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_VENDOR_LOAD', response.data)
            }
          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    getVendortype({ commit }) {
      dropdown.vendortype().then(response => {
        commit('VENDORTYPE_LOAD', response.data);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    getVendorTypeTable({ commit }) {
      repository.vendortype().then(response => {
        commit('VENDOR_TYPE_TABLE_LOAD', response.data);
        commit('VENDOR_TYPE_LOADED', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    addVendorType({ commit }, payload) {
      repository.addvendortype(payload).then(response => {
        commit('NEW_vendortype', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    editVendorType({ commit }, payload) {
      const params = {
        id: payload.id,
        code: payload.payload.code,
        vendorType: payload.payload.vendorType,
        description: payload.payload.description,
      }
      repository.editvendortype(params).then(() => {
        commit('NEW_vendortype', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    deleteVendorType({ commit }, payload) {
      repository.deletevendortype(payload).then(() => {
        commit('NEW_vendortype', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    getAddressByVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getAddressByVendor(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
  },
  mutations: {
    ALL_VENDOR_LOAD: (state, data) => {
      state.vendor = data;
    },
    VENDORTYPE_LOAD: (state, data) => {
      state.vendor_type = data;
    },
    VENDOR_TYPE_TABLE_LOAD: (state, data) => {
      state.vendor_type_table = data;
    },
    NEW_VENDOR: (state, data) => {
      state.newvendor = data;
    },
    NEW_vendortype: (state, data) => {
      state.newvendortype = data;
    },
    VENDOR_TYPE_LOADED: (state, data) => {
      state.vendor_type_loader = data
    },
    vendor_LOAD: (state, data) => {
      state.vendor = data;
    },
    EDIT_VENDORS: (state, data) => {
      state.selected_vendor = data;
    },
    EDIT_VENDOR_TYPE: (state, data) => {
      state.selected_vendorType = data;
    },
    EDITED_vendorS: (state, payload) => {
      const index = state.allvendor.findIndex(t => t.id == payload.id)
      state.allvendor.splice(index, 1, payload);
    },
    TOTAL_vendor: (state, data) => {
      state.totalvendor = data;
    },
    REMOVE_vendor: (state, id) => (state.allvendor = state.allvendor.filter(vendor => vendor.id !== id)),
    COUNTER: (state, data) => {
      state.counter = data;
    }
  },
};
