import api from '../../../api'
export default {

    acctPayableGet(params){
        return api.get(`/accounting/payable/`+params.url,{params})
    },
    acctPayablePost(params){
        return api.post(`/accounting/payable/`+params.url,params)
    },
    acctPayablePut(params){
        return api.put(`/accounting/payable/resource/`+params.url)
    },
    acctPayableDelete(params){
        return api.delete(`/accounting/payable/resource/`+params.url)
    },
    createvendorpaymentdetail(params,config){
        return api.post(`accounting/payable/createVendorPaymentDetail`,params,config)
    },
    getVendorPaymentList(id){
        return api.get(`accounting/payable/getVendorPaymentList/${id}`);
    },
    updatePaymentVoucher(id,params){
        return api.put(`accounting/payable/updatePaymentVoucher/${id}`,params);
    },
}
