import repository from "@/api/modules/Operations/ContainerDiscrepancy";

export default {
    state: {
        containers_selection:[],
        discrepancy_selection:[],
        all_container_discrepancy:[],
        selected_container_discrepancy:[],
        container_discrepancy_files:[],
        export_container_discrepancy:[],
    },
    getters: {
        GET_ALL_CONTAINERS_SELECTION: state => state.containers_selection,
        GET_ALL_DISCREPANCY_SELECTION: state => state.discrepancy_selection,
        GET_ALL_CONTAINER_DISCREPANCY: state => state.all_container_discrepancy,
        GET_SELECTED_CONTAINER_DISCREPANCY: state => state.selected_container_discrepancy,
        GET_CONTAINER_DISCREPANCY_FILES: state => state.container_discrepancy_files,
        GET_EXPORT_CONTAINER_DISCREPANCY: state => state.export_container_discrepancy,
    },
    actions: {
        postContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cdpost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createContainerDiscrepancy(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateContainerDiscrepancy(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cdput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cddelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getContainerDiscrepancy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cdget(payload).then((response) => {
                    if (response.status == 200) {
                        commit(payload.mutation,response.data)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getContainerDiscrepancyFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getContainerDiscrepancyFiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CONTAINER_DISCREPANCY_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        CONTAINERS_SELECTION: (state, data) => { state.containers_selection = data; }, 
        DISCREPANCY_SELECTION: (state, data) => { state.discrepancy_selection = data; }, 
        ALL_CONTAINER_DISCREPANCY: (state, data) => { state.all_container_discrepancy = data; }, 
        SELECTED_CONTAINER_DISCREPANCY: (state, data) => { state.selected_container_discrepancy = data; }, 
        CONTAINER_DISCREPANCY_FILES: (state, data) => { state.container_discrepancy_files = data; }, 
        EXPORT_CONTAINER_DISCREPANCY: (state, data) => { state.export_container_discrepancy = data; }, 
    }

}
