import api from '../../../api'
export default {

    acctBanksGet(params){
        return api.get(`/accounting/`+params.url,{params})
    },
    acctBanksPost(params){
        return api.post(`/accounting/`+params.url,params)
    },
}
