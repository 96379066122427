import repository from "@/api/modules/Accounting/PackingListHistory";

export default {
    state: {
   
    },
    getters: {
        
    },
    actions: {
        postPackingListHistory({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postPackingListHistory(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPackingListHistory({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getPackingListHistory(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
       
    }

}
