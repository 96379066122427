import repository from '@/api/modules/MasterData'

export default {
  state: {
    salesman_position: [],
    createSuccess: false,
    selected_salesman_position: [],
    salesman_position_dialog: false,
  },
  getters: {
    GET_SALESMAN_POSITION_LIST: state => state.salesman_position,
    NEW_SALESMAN_POSITION: state => state.createSuccess,
    SELECTED_SALESMAN_POSITION: state => state.selected_salesman_position,
    SALESMAN_POSITION_DIALOG: state => state.salesman_position_dialog,
  },
  actions: {
    getSalesmanposition({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .salesmanposition()
          .then(({ data }) => {
            resolve(data);
            commit('SALESMAN_POSITION_LIST', data)
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    addsalesmanposition({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addsalesmanposition(payload)
          .then(({ data }) => {
            resolve(data);
            commit('NEW_SALESMAN_POSITION', true)
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    updateallsalesmanposition({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .updateallsalesmanposition(payload)
          .then(({ data }) => {
            resolve(data);
            commit('NEW_SALESMAN_POSITION', true);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    editsalesmanposition({ commit }, payload) {
      const params = {
        id: payload.id,
        title: payload.payload.title,
        description: payload.payload.description
      };

      repository.updatesalesmanpositions(params).then(() => {
        commit('NEW_SALESMAN_POSITION', true);
      })
    },
    deleteSalesmanPosition({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deletesalesmanposition(payload)
          .then(({ data }) => {
            resolve(data);
            commit('REMOVE_SALESMAN_POSITION', payload)
          })
          .catch(error => {
            reject(error);
          })
      });
    },
  },
  mutations: {
    SALESMAN_POSITION_LIST: (state, data) => {
      state.salesman_position = data;
    },
    SELECTED_SALESMAN_POSITION: (state, data) => {
      state.selected_salesman_position = data;
    },
    REMOVE_SALESMAN_POSITION: (state, id) =>
      (state.salesman_position = state.salesman_position.filter(salesman_position => salesman_position.id !== id)),
    NEW_SALESMAN_POSITION: (state, data) => {
      state.createSuccess = data;
    },
    SALESMAN_POSITION_DIALOG: (state, data) => {
      state.salesman_position_dialog = data;
    }
  },
};
