import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/credit-memo/:id",
    name: "acctcredit",
    component: () => import('@/views/main/modules/Accounting/CreditMemo/CreditMemoGroupComponent.vue'),
    props: {
        origin: 1,
    },
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo (Local)',
    }
    },
    {
        path: "/accounting/credit-memo-history/:id",
        name: "acctcreditmemohistory",
        component: () => import('@/views/main/modules/Accounting/CreditMemoHistory/CreditMemoHistoryGroupComponent.vue'),
        props: {
            origin: 1,
        },
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Credit Memo (Change Date)',
        }
    }
]

export default routes;
