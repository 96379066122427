import repository from "@/api/credentials";

const routes = [{
    path: 'reports/accounting/credit-memo-detailed/:id',
    name: 'credit-memo-detailed-component',
    component: () => import('@/views/main/modules/Reports/AccountingReports/CreditMemoDetailedComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo Detailed Report',
    }
},
{
    path: 'reports/accounting/debit-memo-detailed/:id',
    name: 'debit-memo-detailed-component',
    component: () => import('@/views/main/modules/Reports/AccountingReports/DebitMemoDetailedComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Debit Memo Detailed Report',
    }
},
{
    path: 'reports/accounting/soa-payment-status-report/:id',
    name: 'soa-payment-status-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/AccountReceivablesCollectionComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SOA Payment Status Report',
    }
},
{
    path: 'reports/accounting/net-ar-report/:id',
    name: 'net-ar-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/NetArReportComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Net A/R Report',
    }
},
{
    path: 'reports/accounting/cheque-report/:id',
    name: 'cheque-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/ChequeReportComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Collection Report',
    }
},
{
    path: 'reports/accounting/cheque-deposit-report',
    name: 'cheque-deposit-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/ChequeDepositComponent.vue'),
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'Cheque Deposit Report',
    }
},
{
    path: 'reports/accounting/bank-transaction-report/:id',
    name: 'bank-transaction-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/BankTransactionComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Cheque Deposit Report',
    }
},
{
    path: 'reports/accounting/bounced-cheque-report/:id',
    name: 'bounced-cheque-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/BouncedChequeComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Bounced Cheque Report',
    }
},
{
    path: 'reports/accounting/credit-memo-china-discrepancy-report/:id',
    name: 'credit-memo-china-discrepancy-report',
    component: () => import('@/views/main/modules/Reports/AccountingReports/CreditMemoChinaDiscrepancyReportComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo China Discrepancy Report',
    }
},
{
    path: 'reports/accounting/unconfirmed-packing-list-greater-5days/:id',
    name: 'unconfirmed-packing-list-greater-5days',
    component: () => import('@/views/main/modules/Reports/AccountingReports/UnconfirmedPackingList.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Unconfirmed Delivery Receipt (> 5days)',
    }
},
]

export default routes;
