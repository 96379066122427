import repository from "@/api/credentials";

const routes = [{
    path: "/reports/sales/:id",
    name: "reportssales",
    component: () => import('@/views/main/modules/Reports/ReportsComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Reports',
        type: 'sales'
    },
},
{
    path: "/reports/inventory/:id",
    name: "reportinventory",
    component: () => import('@/views/main/modules/Reports/ReportsComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inventory Reports',
        type: 'inventory'
    },
},
{
    path: "/reports/accounting/:id",
    name: "reportaccounting",
    component: () => import('@/views/main/modules/Reports/ReportsComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Accounting Reports',
        type: 'accounting'
    }
}]

export default routes;
