import repository from "@/api/credentials.js";

export default {
  state: {
    user: [],
    access: [],
    foundUser: false,
    getEmailUsername: [],
    code: '',
    // otpCode: [],
  },
  getters: {
    USERACCOUNT_LOAD: (state) => state.user,
    USER_ACCESS: (state) => state.access,
    FOUND_USER:(state) => state.foundUser,
    GET_EMAIL_USERNAME: (state) => state.getEmailUsername,
    GET_VERIFICATION_CODE: (state) => state.code,
    // GET_OTP_CODE: (state) => state.otpCode,
  },
  actions: {
    checkAccessUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.checkAccessUser(payload).then((response) => {
            commit("USER_ACCESS", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
    credentials({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .useraccount()
          .then(({ data, status }) => {
            if (status == 200) {
              commit('USERACCOUNT_LOAD', data)
              resolve(data)
            }
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .login(payload)
          .then(({ data, status }) => {
            if (status == 200) {
              resolve(data);
            }else{
              resolve(data);
            }
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        repository
          .logout()
          .then(({ status }) => {
            if (status == 200) {
              localStorage.removeItem("token");
              resolve(true)
            }
            commit()
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
    forgotPassword({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .forgotPassword(payload)
          .then(({data, status }) => {
            commit('EMAIL_USERNAME', data)
            let credentials = btoa('credentials_id');
            window.localStorage.setItem(credentials, data.credentials_id)
            if (status == 200) {
              state.foundUser = true;
              resolve(true)
            }
          })
          .catch((error) => {
            reject(error)
          });
        });
      },
      sendCode({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
          repository
            .sendCode(payload)
            .then((response) => {
              state.code = response.data.code
              if (response.status == 202) {
                resolve(response.data)
              }
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
      sendEmail({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
          repository
            .sendEmail(payload)
            .then(({data, status }) => {
              // let credentials = btoa('credentials_id');
              // window.localStorage.setItem(credentials, data.credentials_id)
              if (status == 200) {
                resolve(true)
              }
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
      resetPassword({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
          repository
            .resetPassword(payload)
            .then(({data, status }) => {
              if (status == 200) {
                state.foundUser = true;
                resolve(true)
              }
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
    },
  mutations: {
    USERACCOUNT_LOAD: (state, data) => {
      state.user = data;
    },
    USER_ACCESS: (state, data) => {
      state.access = data;
    },
    EMAIL_USERNAME: (state, data) => {
      state.getEmailUsername = data;
    },
    // OTP_CODE: (state, data) => {
    //   state.otpCode = data;
    // }
  }
}
