import api from '../../../api'
export default{

    cdget(params){
      return api.get(`/container-discrepancy/`+params.url,{params})
    },
    cdpost(params){
      return api.post(`/container-discrepancy/`+params.url,params)
    },
    cdput(params){
      return api.put(`/container-discrepancy/`+params.url,params)
    },
    cddelete(params){
      return api.delete(`/container-discrepancy/`+params.url,params)
    },
    createContainerDiscrepancy(params){
      return api.post(`/container-discrepancy/create-container-discrepancy`,params.params,params.config)
    },
    updateContainerDiscrepancy(params){
      return api.post(`/container-discrepancy/update-container-discrepancy`,params.params,params.config)
    },
    getContainerDiscrepancyFiles(id){
      return api.get(`container-discrepancy/get-container-discrepancy-files/${id}`);
  },

}
