import repository from "@/api/credentials";

const routes = [{
    path: "/sales/promos/:id",
    name: "salespromo",
    component: () => import('@/views/main/modules/Sales/Promos/PromosComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Promos',
    }
}]

export default routes;
