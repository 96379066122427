import api from '../../api'
export default {

    getallstockadjustment(params){
        return api.get('stock-adjustment/it/getAll',{params});
    },
    getallwarehouses(params){
        return api.get('dropdown/getWarehouses',{params});
    },
    userslistActive(params){
        return api.get('dropdown/getUsers',{params});
    },
    getusernamenonselect(params){
        return api.get('stock-adjustment/it/getUsername',{params:params});
    },
    getitemsbymodel(params){
        return api.post('masterdata/items/details',params);
    },
    addnewstockadjustment(params){
        return api.post('stock-adjustment/it/addNewAdjustment',params.params,params.config)
    },
    getstockadjustment(params){
        return api.get('stock-adjustment/it/getStockAdjustment',{params});
    },
    updatestockadjustment(params){
        return api.post('stock-adjustment/it/updateStockAdjustment',params);
    },
    deletestockadjustment(params){
        return api.delete('stock-adjustment/it/deleteAdjustment',{params:params});
    },
    updateadjustment(params){
        return api.post('stock-adjustment/it/updateAdjustment',params.params,params.config);
    },
    getwarehouseitemstocks(params){
        return api.get('inventory/getWarehouseItemStock',{params});
    },
    exportstockadjusment(params){
        return api.get('stock-adjustment/it/exportstockadjusment',{params});
    },
    getcurrentuser(params){
        return api.get('stock-adjustment/it/get-current-user',{params});
    },
    getStockAdjustmentFiles(id){
        return api.get(`stock-adjustment/it/get-stock-adjustment-files/${id}`);
    },
    deleteStockAdjustmentFile(params){
        return api.post(`stock-adjustment/it/delete-stock-adjustment-file/`,params);
    },
    getWarehouseDefault(params){
        return api.get('dropdown/getWarehouseDefault',{params});
    },
    getStocksInquiryBatch(params){
        return api.post('masterdata/getStocksInquiryBatch',params);
    },
    getStocksInquiryTable(params){
        return api.get('masterdata/getStocksInquiryTable',{params});
    },
    checkItemSubstitution(params){
        return api.get('masterdata/checkItemSubstitution',{params});
    },
}
