import repository from "@/api/modules/Operations/StockVariance";

export default {
    state: {
        
    },
    getters: {
       
    },
    actions: {
        updateStockVarianceStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatestockvariancestatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        saveStockVariance({commit}) {
            return new Promise((resolve, reject) => {
                repository.savestockvariance().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllStockVariance({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallstockvariance(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
       
    },
    mutations: {
        

    }

}
