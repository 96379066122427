import repository from "@/api/modules/Operations";

export default {

    actions: {
        async inventoryGet({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.inventoryGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },

    mutations: {

    }

}
