<template>
    <div class="text-center">
      <v-dialog
        v-model="dialogs"
        :width="dialogWidth"
        persistent
        overlay-opacity="0.9"
      >
        <v-card>
          <v-card-title class="border-bottom">
       
          </v-card-title>
  
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <span class="text-h5 font-weight-bold"> Change Password </span>
                  <p class="pl-5 font-weight-bold">Password must contain:</p>
                  <label class="pl-6"><span style="color:green" v-if="password_validation.textLen">&#x2713;</span><span style="color:red" v-if="!password_validation.textLen && form.new_password">&#10005;</span> At least 8 Characters</label>
                  <v-row v-if="password_validation.currentpasswordMatch">
                <v-col>
                  <v-alert
                    dense
                    border="left"
                    type="error"
                    dense
                    >
                    <span style="font-size:12px;">Your new password cannot be the same as your old password</span>
                  </v-alert>
                </v-col>
              </v-row>
                </v-col>
                <v-col cols="12" lg="6" class="pa-5">
                  <v-row>
                <v-col>
                  <v-alert
                    dense
                    border="left"
                    text
                    type="warning"
                    outlined
                    color="orange accent-4"
                    >
                    <span style="font-size:12px;">Change the old password. This is a must for all new users.</span>
                  </v-alert>
                </v-col>
              </v-row>
                 
                  <v-row>
                <v-col cols="12"><v-text-field type="password" v-model="form.current_password" dense outlined label="Old Password">
                  <template #append>
                  <span style="color:red" v-if="password_validation.currentPasswordNotmatch">&#10005;</span>
                </template>
                </v-text-field>
               </v-col>
              </v-row>
                  <v-row>
                <v-col cols="12"><v-text-field type="password" v-model="form.new_password" dense outlined label="Password" @keyup="passwordValidation()">
                <template #append>
                  <span style="color:green" v-if="password_validation.passwordMatch">&#x2713;</span>
                  <span style="color:red" v-if="!password_validation.passwordMatch && form.new_password">&#10005;</span>
                </template>
                </v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><v-text-field type="password" v-model="form.new_password_confirmation" dense outlined label="Confirm Password" @keyup="passwordValidation()">
                  <template #append>
                  <span style="color:green" v-if="password_validation.passwordMatch">&#x2713;</span>
                  <span style="color:red" v-if="!password_validation.passwordMatch && form.new_password">&#10005;</span>
                </template></v-text-field></v-col>
              </v-row>
              
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
            small
              color="secondary"
              @click="cancelChagePassword()"
            >
            CANCEL
            </v-btn>
            <v-btn
            small
              color="primary"
              @click="saveChangePass()"
            >
            SAVE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import {mapGetters} from 'vuex';
  export default {
    data () {
      return {
        dialogs:false,
        form:{
          current_password:'',
          new_password:'',
          new_password_confirmation:''
        },
        password_validation:{
          textLen:false,
          currentpasswordMatch:false,
          passwordMatch:false,
          currentPasswordNotmatch:false,
        }
      }
    },
    computed:{
        ...mapGetters(['USERACCOUNT_LOAD']),
        dialogWidth () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '50%'
          case 'md': return '50%'
          case 'lg': return '40%'
          case 'xl': return '30%'
        }
      },
        dialog(){
        if(this.USERACCOUNT_LOAD.change_password_next == 1){
          this.dialogs = true;
          return true
        }else{
          this.dialogs = false;
          return false
        }
        }
    },
    created(){
       this.dialog
    },
    methods:{
      passwordValidation(){
        this.password_validation.currentpasswordMatch = false
        if(this.form.new_password.length > 7){
          this.password_validation.textLen = true
        }else{
          this.password_validation.textLen = false
       
        }
        if(this.form.new_password_confirmation  && this.form.new_password){
          this.password_validation.passwordMatch = false
          if(this.form.new_password_confirmation === this.form.new_password){
          this.password_validation.passwordMatch = true
          }
        else{
        this.password_validation.passwordMatch = false
       }
        }
        else
        {
          this.password_validation.passwordMatch = false
        }
     
      
      },
      saveChangePass(){
       
        if(this.password_validation.textLen && this.password_validation.passwordMatch && this.form.current_password != this.form.new_password){
          this.$store.dispatch('postChangePassword',this.form).then((response)=>{
            this.dialogs =  false;
          }).catch(e=>{
            this.password_validation.currentPasswordNotmatch = true
          });
        }
        else if(this.form.current_password == this.form.new_password){
          this.password_validation.currentpasswordMatch = true
        }
        
      },
      cancelChagePassword(){
          this.$store.dispatch("logout").then(() => {
              this.$router.push({ name: "login" });
              this.$store.commit('USERACCOUNT_LOAD', {})
          });
      }
    },
    watch:{
      USERACCOUNT_LOAD:{
        handler(val){
          this.dialog
        }
      }
    }
  }
</script>