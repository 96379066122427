import repository from "@/api/modules/Accounting/StatementOfAccount";

export default {
    state: {
        soa:[],
        pl_by_customer:[],
        debit_memo_by_customer:[],
        credit_memo_by_customer:[],
        customer_payment_list:[],
        all_soa:[],
        soa_payments:[],
        print_summary: {}
    },
    getters: {
        GET_SOA: (state) => state.soa,
        GET_PL_BY_CUSTOMER: (state) => state.pl_by_customer,
        GET_DEBIT_MEMO_BY_CUSTOMER: (state) => state.debit_memo_by_customer,
        GET_CREDIT_MEMO_BY_CUSTOMER: (state) => state.credit_memo_by_customer,
        GET_CUSTOMER_PAYMENT_LIST: (state) => state.customer_payment_list,
        GET_ALL_SOA: (state) => state.all_soa,
        GET_SOA_PAYMENTS: (state) => state.soa_payments,
        GET_PRINT_SUMMARY: (state) => state.print_summary,
    },
    actions: {
        postStatementOfAccounts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.poststatementofaccounts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoa({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getsoa(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SOA',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateSoa({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatesoa(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPLByCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getplbycustomer(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PL_BY_CUSTOMER',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDebitMemoByCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getdebitmemobycustomer(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DEBIT_MEMO_BY_CUSTOMER',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoByCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcreditmemobycustomer(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CREDIT_MEMO_BY_CUSTOMER',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerPaymentList({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerpaymentlist(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_PAYMENT_LIST',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllSoa({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallsoa(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_SOA',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportPaymentDetailsPerCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.exportPaymentDetailsPerCustomer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteSoa({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletesoa(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateSoaStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatesoastatus(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoaPayments({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getsoapayments(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SOA_PAYMENTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        soaGetPlById({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getsoaplbyid(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoaPerPeriod({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSoaPerPeriod(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoaByPaymentDetailId({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSoaByPaymentDetailId(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        allocateRemainingBalance({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.allocateRemainingBalance(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPaymentAllocationBySoaId({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getPaymentAllocationBySoaId(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelSelectedPayment({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelSelectedPayment(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelCustomerPaymentAllocations({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelCustomerPaymentAllocations(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTermsByCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getTermsByCustomer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoasByCustomerId({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSoasByCustomerId(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        assignUnidentifiedPayment({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.assignUnidentifiedPayment(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoaCardDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSoaCardDetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        printSummarySOA({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.printSummarySOA(payload).then((response) => {
                    resolve(response.data)
                    commit('PRINT_SUMMARY',response.data)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmBadDebt({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.confirmBadDebt(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        // ALL_COST_ITEM: (state, data) => { (state.all_cost_item = data) },
        // ACCT_GET_ITEMS: (state, data) => { (state.accounting_get_item = data) },
        SOA: (state, data) => { (state.soa = data) },
        PL_BY_CUSTOMER: (state, data) => { (state.pl_by_customer = data) },
        DEBIT_MEMO_BY_CUSTOMER: (state, data) => { (state.debit_memo_by_customer = data) },
        CREDIT_MEMO_BY_CUSTOMER: (state, data) => { (state.credit_memo_by_customer = data) },
        CUSTOMER_PAYMENT_LIST: (state, data) => { (state.customer_payment_list = data) },
        ALL_SOA: (state, data) => { (state.all_soa = data) },
        SOA_PAYMENTS: (state, data) => { (state.soa_payments = data) },
        PRINT_SUMMARY: (state, data) => { (state.print_summary = data) },
    }

}
