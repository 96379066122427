import repository from '@/api/modules/Operations/Inbound'
import it_repository from '@/api/modules/it'

export default {
  state: {
    get_all_inbound: [],
    view_inbound: [],
    add_new_inbound: [],
    edit_inbound: [],
    receive_inbound: [],
    approve_inbound: [],
    reject_inbound: [],
    delete_inbound: [],
    export_inbound: [],
    access:[],
    selected_filter:'',
    all_lpi_details: [],
    inbound_upload_file: [],
  },
  getters: {
    GET_ALL_INBOUND: state => state.get_all_inbound,
    VIEW_INBOUND: state => state.view_inbound,
    ADD_NEW_INBOUND: state => state.add_new_inbound,
    EDIT_INBOUND: state => state.edit_inbound,
    RECEIVE_INBOUND: state => state.receive_inbound,
    APPROVE_INBOUND: state => state.approve_inbound,
    REJECT_INBOUND: state => state.reject_inbound,
    DELETE_INBOUND: state => state.delete_inbound,
    EXPORT_INBOUND: state => state.export_inbound,
    ACCESS_INBOUND: state => state.access,
    SELECTED_FILTER: state=>state.selected_filter,
    GET_ALL_API_DETAILS: state => state.all_lpi_details,
    GET_INBOUND_UPLOAD_FILES: state => state.inbound_upload_file,
  },
  actions: {
    getAllInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getallinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    viewInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .viewinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('V_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    addNewInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addnewinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('NEW_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    editInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .editinbound(payload)
          .then(({ data }) => {
            resolve(data);
            commit('E_INBOUND', true)
          })
          .catch(e => {
            reject(e);
          })
      });
    },
    receiveInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .receiveinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REC_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    approveInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .approveinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('APP_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    rejectInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .rejectinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REJ_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    deleteInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deleteinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('DEL_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    exportInbound({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .exportinbound(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('EXP_INBOUND', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    exportLpiToExcel({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .exportlpitoexcel(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_LPI_DETAILS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    uploadInboundFile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .uploadinboundfile(payload.params,payload.config)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getInboundFiles({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.getinboundfiles(payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response)
            commit('INBOUND_UPLOAD_FILE', response.data);
          }
        })
        .catch(error => {
          reject(error);
        })
      })
    },
    inboundDeleteFile({commit},payload) {
      return new Promise((resolve, reject) => {
          repository.deleteinboundfile(payload).then((response) => {
              if (response.status == 200) {
                  resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    }
  },
  mutations: {
    ALL_INBOUND: (state, data) => {
      state.get_all_inbound = data;
    },
    V_INBOUND: (state, data) => {
      state.view_inbound = data;
    },
    NEW_INBOUND: (state, data) => {
      state.add_new_inbound = data;
    },
    E_INBOUND: (state, data) => {
      state.edit_inbound = data;
    },
    REC_INBOUND: (state, data) => {
      state.receive_inbound = data;
    },
    APP_INBOUND: (state, data) => {
      state.approve_inbound = data;
    },
    REJ_INBOUND: (state, data) => {
      state.reject_inbound = data;
    },
    DEL_INBOUND: (state, data) => {
      state.delete_inbound = data;
    },
    EXP_INBOUND: (state, data) => {
      state.export_inbound = data;
    },

    ACCESS_INBOUND: (state, data) => {
        state.access = data;
    },
    SELECTED_FILTER:(state,data) =>{
        state.selected_filter = data;
    },
    ALL_LPI_DETAILS:(state, data) => {
      state.all_lpi_details = data;
    },
    INBOUND_UPLOAD_FILE: (state, data) => {
      state.inbound_upload_file = data;
    }
  },
}
