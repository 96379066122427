import repository from "@/api/modules/Sales/PackingList";

export default {
    state: {
        // export_pending_so_list_report: [],
        STATUS:[
            'pending',
            'confirmed',
            'rejected',
            'canceled',
            'deleted',
            'submitted',
            'for approval',
            'approved',
        ]
    },
    getters: {
        // GET_PENDING_SO_LIST_REPORT: (state) => state.export_pending_so_list_report,
        PACKING_LISTS:(state)=>state,
    },
    actions: {
        postPL({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.plpost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putPL({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.plput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deletePL({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.pldelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPL({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.plget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        // PENDING_SO_LIST_REPORT: (state, data) => { (state.export_pending_so_list_report = data) }
    }

}
